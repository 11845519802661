;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var storage = {
    };

    var production = process.env.NODE_ENV === 'production'

    /********************/
    /* Public Functions */
	/********************/
    var Storage = {
        // Initialize function
		init: function(callback) {
			if (callback) {
				return callback(this);
            }
        },

        offlineMode() { return !!!window.location.hostname },
        serverHost() { return window.location.hostname || this.get('serverHost') },
        serverPort() { return !production ? 80 : window.location.port || this.get('serverPort') || 80 },
        serverProtocol() { return window.location.protocol !== 'file:' ? window.location.protocol || this.get('serverProtocol') : this.get('serverProtocol') },
        serverURL() { return !production ? this.serverProtocol() + '//192.168.10.100' : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() },
        mobilePath() { return !production ? this.serverProtocol() + '//' + this.serverHost() + ':8082' : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() + '/mobile' },
        assetsPath() { return this.serverURL() + '/contents/' },
        proxyPath() { return this.serverProtocol() + '//' + this.serverHost() + ':1' + this.serverPort() + '/fetch/' },

        // Set value to the storage
        // PARAMS: {String} name, {Object/String/Number} value
        // RETURNS: {Boolean}
        set: function(name, value) {
            if (window.localStorage) {
                return window.localStorage.setItem(name, JSON.stringify(value));
            }

            return false;
        },
        
        // Get value from the storage
        // PARAMS: {String} name
        // RETURNS: {Object/String/Number} or FALSE 
        get: function(name) {
            var value;

            if (window.localStorage) {
                value = window.localStorage.getItem(name);
                if(typeof value !== 'undefined'){
                    try{
                        return JSON.parse(value);
                    }
                    catch(e){
                        return value;
                    }
                }
            }

            return false;
        },

        // Clear all stored data
        // RETURNS: {Boolean}
        clear: function(){
            if(window.localStorage){
                return window.localStorage.clear();
            }

            return false;
        },

        // Handles downloading application to local storage for offline operation
        // RETURNS: {Boolean}
        storeAppOffline: function(callback) {
            callback(null, true)
        },

        // RETURNS: {Boolean}
        removeAppOffline: function() {
        },
        
        // Handles downloading project static assets to local storage for offline operation
        // RETURNS: {Boolean}
        storeProjectDataOffline: function(projectObj, callback) {
            callback(null, true)
        },
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
    
    window.ScreenStorage = Storage;

    if (typeof module === 'object') {
        module.exports = Storage;
    }
})();