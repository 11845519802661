<template>
  <div :style="makePageFullScreen()" >
    <div :style="placeDivInPageCenter()" ref="contentDiv" v-if="panelOn"> 
      <h1>Screen is ready</h1>
      <h3>No content available</h3>
    </div>
    <div :style="screenOffStyle()" v-else> 
    </div>
  </div>
</template>

<script>

export default {
  name: 'Landing',
  components: {
  },
  data() {
    return {
      userAgent:navigator.userAgent,
      width:window.innerWidth,
      height:window.innerHeight,
      divHeight: 0,
      panelOn:true,
      network:{},
      device:{},
      storage:{}
    }
  },
  mounted(){
    this.network=window.Network
    this.device=window.Device
    this.storage=window.ScreenStorage

    this.calculateDivHeight()
    if(this.$socket.connected){
      this.registerReceivers()
      this.getContent()
    }
    else{
      console.log('can not register receiver')
    }
  },
  beforeUnmounted(){
    this.$socket.off('unregister')
    this.$socket.off('volume')
    this.$socket.off('power')
  },
  methods:{
    registerReceivers(){
      this.$socket.on('unregister', ()=>{
        this.$emit("deleted", {number: this.$root.deviceNumber})
      })
       this.$socket.on('volume', (data)=>{
        this.device.volume(data.value)
        this.$socket.send('volumeChanged', { 
          uid:this.device.serial(),
          volume:this.device.volume()
        },
        (response)=>{
          // i see 3 possible things here: a. not registered, b. not compatible, c. registered
          console.log(response)
        })
      })
       this.$socket.on('power', (data)=>{
        this.device.panel(data.value)
        this.panelOn=data.value
        this.$socket.send('panelChanged', { 
          uid:this.device.serial(),
          panel:this.device.panel()
        },
        (response)=>{
          // i see 3 possible things here: a. not registered, b. not compatible, c. registered
          console.log(response)
        })
      })
      this.$socket.on('reboot', (data)=>{
         this.device.reboot()
      })
    },
    getContent(){
       this.$socket.send('getDeviceContent', function(data,callback){
        console.log(data)
      })
    },
    makePageFullScreen(){
      var css='background-color:#262020;'
      css+='width:'+this.width+'px;'
      css+='height:'+this.height+'px;'
      css+='color:white;'
      return css
    },
    placeDivInPageCenter(){
      let divCss = 'position:absolute;width:100%;'
      divCss+='top:'+((this.height-this.divHeight)/2)+'px;'
      return divCss
    },
    calculateDivHeight() {
      this.divHeight = this.$refs.contentDiv.offsetHeight
    },
    screenOffStyle(){
      let screenCss = 'background-color:#000;width:100%;height:100%'
      return screenCss
    }
  }, 
}
</script>
<style scoped>
.bg{
  background-color: #262020;
}
</style>

