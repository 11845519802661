;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var wifiShouldBeOn = false
    var tvHcap = "0.00"
    var netflixRegistered = false
    var netflixData = false
    
    /********************/
    /* Public Functions */
	/********************/
    var Network = {
        // Initialize function
		init: function(callback) {
            var self = this

            document.addEventListener("property_changed", function(r) { 
                if(r.key === 'soft_ap') self.softApEnabled()
                else if(r.key === 'room_number') self.SSID()
            })
            document.addEventListener("network_event_received", function(r) { handleNetEvent(r.event) })
            document.addEventListener("hcap_application_focus_changed", function(r) { 
                if(r.eventType === 'focused' && wifiShouldBeOn) {
                    self.softApEnabled(true)
                    wifiShouldBeOn = false
                }
            })

            // Get TVs hcap version
            hcap.property.getProperty ({
                "key" : "hcap_middleware_version",
                "onSuccess" : function(s) { tvHcap = s.value.substring(0,4) },
                "onFailure" : function(f) { console.error('Failed to get TV HCAP version: reason = ' + f.errorMessage) }
            })

            initNetworkParams(function(error, net) {
                if(error) console.error('Could not initialize network ' + error)
                callback(null, self)
            })
        },

        openNetflix(reason) {
            if(!this.canPlayNetflix())   return
                
            // check if netflix registered
            this.registerNetflix(function(error, data) {
                if(error) return global.vm.$root.$notify(error)
                hcap.preloadedApplication.launchPreloadedApplication({
                    "id":"244115188075859013",
                    "parameters":`{'reason':'${reason}','params': {'hotel_id': '${data.id}','launcher_version': '${data.launchVersion}'}}`,
                    "onSuccess":function() {
                        console.log("Netflix launched");
                        socketio.emit("appLaunch", "netflix");
                    },
                    "onFailure":function(f) {
                        console.error("onFailure Netflix launch: reason = " + f.errorMessage);
                        global.vm.$root.$notify("Netflix launch failed")
                    }
                })
            }.bind(this))
        },

        canPlayNetflix() {
            // check if hcap is OK
            if(tvHcap < "1.24") {
                console.error("Incompatible TV for launching Netflix, HCAP < 1.24")
                global.vm.$root.$notify("Incompatible TV for launching Netflix")
                return false
            }
            // check if Checked in
            if(! ('language' in global.vm.$root.guest))  {
                console.error("Not checked in, cannot launch Netflix")
                global.vm.$root.$notify("Not checked in, cannot launch Netflix")
                return false
            }
            return true
        },

        registerNetflix(callback) {
            if(netflixRegistered) return callback(null, netflixData)
            socketio.emit("getAppToken", "netflix", timeout(function(timedout, error, data) {
                if(timedout) return global.vm.$root.$notify("Operation Timed Out")
                if(error) {
                    console.error(error)
                    return global.vm.$root.$notify(error)
                }

                netflixData = data
                document.addEventListener("application_registration_result_received", function handler(param) {
                    document.removeEventListener("application_registration_result_received", handler, false)
                    if(param.tokenResult == 'success' && param.id == 'netflix') {
                        console.log('Netflix registered')
                        netflixRegistered = true
                        callback(null, netflixData)
                    }
                    else if(param.id == 'netflix') {
                        console.error('Netflix registration failed, error: ' + param.errorText)
                        callback('Netflix registration failed', false)
                    }
                }, false )

                hcap.application.RegisterSIApplicationList({
                    "tokenList" : [{"id":"netflix","token":netflixData.token}],
                    "onSuccess" : function(s) { console.log("Netflix registering!"); },
                    "onFailure" : function(f) { 
                        console.error("onFailure RegisterSIApplicationList: reason = " + f.errorMessage); 
                        global.vm.$root.$notify('Failed to register Netflix')
                    }
                });
            }))
        },

        openBluetoothPlayer: function() {
            runAppByName('BLUETOOTH SOUND SYNC')
        },

        openScreenMirroring: function() {
            // Check if WiFi is ON
            hcap.property.getProperty({
                "key" : 'soft_ap',
                "onSuccess" : function(s) {
                    wifiShouldBeOn = s.value === '1'

                    // Close WiFi first
                    hcap.property.setProperty({
                        "key" : 'soft_ap',
                        "value": "0",
                        "onSuccess" : function() {
                            // Get model to open correct app
                            hcap.property.getProperty({
                                "key" : 'model_name',
                                "onSuccess" : function(model) {
                                    if(model.value.indexOf('760') !== -1) runAppByName('miracast')    //for 760
                                    else runAppByName('Screen Share')
                                },
                                "onFailure" : function(f) { console.error('Open Screen Mirroring failed ' + f.errorMessage) }
                            });
                        },
                        "onFailure" : function(f) { console.error('Open Screen Mirroring failed ' + f.errorMessage) }
                    });
                },
                "onFailure" : function(f) { console.error('Open Screen Mirroring failed ' + f.errorMessage) }
            })
        },
        
        // Get/Set the IP DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        ipDhcpEnabled: function(mode = '-1') {
            // SET TODO
            
            // GET
            //return network.dhcp
        },

        // Get/Set the DNS DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        dnsDhcpEnabled: function(mode = '-1') {
            // SET TODO

            // GET TODO
            return true
        },

        // SoftAp Functions
        //
        // Get/Set the Soft AP state
        softApEnabled(enable = '-1'){
            // SET
            var self = this
            if(enable == true || enable == false ){
                if(enable && global.vm.$root.Device.mode == 2) return console.error('Cannot enable SoftAP when on WiFi')
                hcap.property.setProperty({
                    "key" : 'soft_ap',
                    "value": enable ? "1" : "0",
                    "onSuccess" : function(s) { 
                        // Fix some LG problem where when turning off disable the SoftAP
                        if(window.localStorage) window.localStorage.softAPshouldBeOn = enable ? "1" : "0"
                        // some LG models do not dispatch soft_ap property changed events, so we need to get the softAP status to see if changed
                        setTimeout(function() { self.softApEnabled() }, 1000)
                        return true
                    },
                    "onFailure" : function(f) { console.error('SoftAP state change failed') }
                });
            } 
            // GET
            else {
                hcap.property.getProperty({
                    "key" : 'soft_ap',
                    "onSuccess" : function(s) { 
                        // Fix some LG problem where when turning off disable the SoftAP
                        if(window.localStorage && window.localStorage.softAPshouldBeOn !== s.value) {
                            self.softApEnabled(window.localStorage.softAPshouldBeOn === '1' ? true : false)
                        }
                        else EventBus.$emit('deviceDataChanged', { 'apStatus': s.value === '1' ? true : false }) 
                    },
                    "onFailure" : function(f) { console.error('SoftAP state change failed ' + f.errorMessage) }
                });
            }
        },
        
        // Get/Set the SSID name
        // returns SSID on Get / true or false on Set
        SSID(name = null) {
            var self = this
            // SET
            if(name !== null){
                hcap.property.getProperty({
                    "key" : 'soft_ap',
                    "onSuccess" : function(s) { 
                        wifiShouldBeOn = s.value === '1'
                        hcap.property.setProperty({
                            "key" : 'room_number',
                            "value": name,
                            "onSuccess" : function(s) { 
                                if(wifiShouldBeOn) {
                                    self.softApEnabled(false)
                                    setTimeout(function() { self.softApEnabled(true) }, 2000)
                                    wifiShouldBeOn = false
                                }
                            },
                            "onFailure" : function(f) { console.error("ERROR: " + f.errorMessage); }
                        }); 
                    },
                    "onFailure" : function(f) { console.error('Set SSID failed ' + f.errorMessage) }
                })
            } 
            // GET
            else {
                hcap.property.getProperty({
                    "key" : 'room_number',
                    "onSuccess" : function(s) { EventBus.$emit('deviceDataChanged', { 'apSsid': s.value }) },
                    "onFailure" : function(f) { console.error("ERROR: " + f.errorMessage); }
                });
            }
        },

        // Get the Soft AP password
        softApPass(pass = null) {
            var self = this
            // GET
            if(pass === null) {
                // Fix LG softAP pass is zero length for unknown reason
                var softAPpass = window.localStorage.apPass || ''
                if(softAPpass.length === 0) self.softApPass('12345678')
                else EventBus.$emit('deviceDataChanged', { 'apPass': window.localStorage.apPass || '' })
            }
            // SET
            else {
                hcap.property.setProperty({ 
                    "key":"soft_ap_password",
                    "value":pass, 
					"onSuccess":function() { 
                        EventBus.$emit('deviceDataChanged', { 'apPass': pass })
                        hcap.property.getProperty({
                            "key" : 'soft_ap',
                            "onSuccess" : function(s) { 
                                wifiShouldBeOn = s.value === '1'
                                if(wifiShouldBeOn) {
                                    self.softApEnabled(false)
                                    setTimeout(function() { self.softApEnabled(true) }, 2000)
                                    wifiShouldBeOn = false
                                }
                            },
                            "onFailure" : function(f) { console.error('Set SSID failed ' + f.errorMessage) }
                        })
                        window.localStorage.apPass = pass
                    }, 
					"onFailure":function(f) { console.error('Failed to change wifi pass')}
				})
            }
        },

        // Enable or disable the auto regeneration of password at reboot
        softApAutoResetPass(enable = -1) {
            return false
        },

        // Get the Soft AP Strength (returns 1-5)
        // Set (input 1-5, returns true/false)
        softApStrength(strength = 0) {
            // SET
            if(strength === 1 || strength === 2 || strength === 3 || strength === 4 || strength === 5) {
                hcap.network.getSoftAP({
                    "onSuccess" : function(s) {
                        hcap.network.setSoftAP({
                            "channel": s.channel,
                            "vlanId": s.vlanId,
                            "vlanIp": s.vlanIp,
                            "vlanSubnet": s.vlanSubnet,
                            "vlanGateway": s.vlanGateway,
                            "signalStrength": strength,
                            "onSuccess" : function() { EventBus.$emit('deviceDataChanged', { 'apStrength': strength }) },
                            "onFailure" : function(r) { console.error('Could not set SoftAP Signal Strength:' + r.errorMessage) }
                        })
                    },
                    "onFailure" : function(f) { console.error("ERROR: " + f.errorMessage); }
                })
            }
            // GET
            else {
                hcap.network.getSoftAP({
                    "onSuccess" : function(s) { EventBus.$emit('deviceDataChanged', { 'apStrength': s.signalStrength }) },
                    "onFailure" : function(f) { console.error("ERROR: " + f.errorMessage); }
                })
            }
        },

        softApChannel(ch = 0) {
            // SET
            if(ch === 1 || ch === 2 || ch === 3 || ch === 4 || ch === 5 || ch === 6 || ch === 7 || ch === 8 || ch === 9 || ch === 10 || ch === 11 || ch === 12) {
                hcap.network.getSoftAP({
                    "onSuccess" : function(s) {
                        hcap.network.setSoftAP({
                            "channel": ch,
                            "vlanId": s.vlanId,
                            "vlanIp": s.vlanIp,
                            "vlanSubnet": s.vlanSubnet,
                            "vlanGateway": s.vlanGateway,
                            "signalStrength": s.signalStrength,
                            "onSuccess" : function(s) { EventBus.$emit('deviceDataChanged', { 'apChannel': ch }) },
                            "onFailure" : function(r) { console.error('Could not set SoftAP Signal Strength:' + r.errorMessage) }
                        })
                    },
                    "onFailure" : function(f) { console.error("ERROR: " + f.errorMessage); }
                })
            }
            // GET
            else {
                hcap.network.getSoftAP({
                    "onSuccess" : function(s) { EventBus.$emit('deviceDataChanged', { 'apChannel': s.channel }) },
                    "onFailure" : function(f) { console.error("ERROR: " + f.errorMessage); }
                })
            }
        }
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
    function initNetworkParams(callback) {
		getNetworkInformation(function(error, netInfo) {
            if(error) return callback('Could not init Network Parameters, ' + error, false)
            informNetInfo()
            Network.softApEnabled()
            Network.SSID()
            Network.softApPass()
            Network.softApChannel()
            Network.softApStrength()
            
            hcap.network.getNumberOfNetworkDevices({
                "onSuccess" : function(s) {
                    EventBus.$emit('deviceDataChanged', { 'nicsCount': s.count})
                    
                    hcap.network.getNetworkDevice({
                        "index" : 0,
                        "onSuccess" : function(res) {
                            EventBus.$emit('deviceDataChanged', { 'mac': res.mac.replace(/:/g, '').toUpperCase()})
                            hcap.network.getNetworkDevice({
                                "index" : 1,
                                "onSuccess" : function(r) {
                                    EventBus.$emit('deviceDataChanged', { 'macw': r.mac.replace(/:/g, '').toUpperCase()})
                                    return callback(null, true)
                                },
                                "onFailure" : function(err) { 
                                    console.error('Failed to get Wireless MAC ' + err.errorMessage)
                                    return callback(null, true)
                                }
                            })
                        },
                        "onFailure" : function(e) { 
                            console.error('Failed to get Wired MAC ' + e.errorMessage)
                            return callback(null, true)
                        }
                    })
                },
                "onFailure" : function(r) {
                    console.error('Failed to get network interfaces ' + r.errorMessage)
                    return callback('Could not get number of network interfaces, ' + error, false)
                }
            })
        })
    }

    function handleNetEvent (event) {
        if (event === 0) {} //UNKNOWN event
        else if (event === 1) {
            EventBus.$emit('deviceDataChanged', {'physicalCon': true, 'local': true})
            setTimeout(function() { informNetInfo() }, 2000)
        }
        else if (event === 2) {
            console.error('Ethernet Unplugged')
            EventBus.$emit('deviceDataChanged', {'physicalCon': false, 'local': false})
        }
        else if (event === 5) console.error('IP address conflict detected!')
        else if (event === 6) console.warn('IP address conflict resolved!')
        else if (event === 7) {
            console.info('DHCP obtain IP success')
            setTimeout(function() { informNetInfo() }, 2000)
        }
        else if (event === 8) console.error('DHCP Failed to obtain IP address')
        else if (event === 9) {
            console.error('Unable to reach Gateway')
            EventBus.$emit('deviceDataChanged', {'local': false})
        }
        else if (event === 10) {
            EventBus.$emit('deviceDataChanged', {'local': true})
            setTimeout(function() { informNetInfo() }, 2000)
        }
        else if (event === 11) console.error('DNS is unreachable')
        else if (event === 12) console.warn('DNS is now reachable')
        else if (event === 13) {
            console.error('Unable to reach Internet')
            EventBus.$emit('deviceDataChanged', {'internet': false})
        }
        else if (event === 14) {
            EventBus.$emit('deviceDataChanged', {'internet': true})
            setTimeout(function() { informNetInfo() }, 2000)
        }
        else if (event === 15) {} // WIFI_AP_SEARCH_COMPLETE
        else if (event === 16) { 
            EventBus.$emit('deviceDataChanged', {'physicalCon': true})
            console.info('Connected to WiFi')
        }
        else if (event === 17) { EventBus.$emit('deviceDataChanged', {'physicalCon': false}); console.error('Failed to connect to WiFi') }
        else if (event === 18) { EventBus.$emit('deviceDataChanged', {'physicalCon': false}); console.warn('WiFi link dropped') }
        else console.error('Unknown network event')
    }

    function getNetworkInformation(callback) {
        hcap.network.getNetworkInformation({
            "onSuccess" : function(s) { callback(null, s) },
            "onFailure" : function(f) { callback(f.errorMessage, false) }
        })
    }

    function informNetInfo() {
        getNetworkInformation(function(error, netInfo) {
            if(error) {
                console.error('Could not get network info: ' + error)
                return
            }
            EventBus.$emit('deviceDataChanged', {
                'ip': netInfo.ip_address,
                'sm': netInfo.subnet_mask,
                'gw': netInfo.gateway,
                'dns1': netInfo.dns1_address,
                'dns2': netInfo.dns2_address,
                'local': netInfo.local_network_available,
                'internet': netInfo.internet_available,
                'dhcp': netInfo.using_dhcp,
                'mode': netInfo.network_mode != 3 ? netInfo.network_mode : 1,       // because when cable unplugged network_mode becomes 3, we want either Ethernet or WiFi
                'physicalCon': netInfo.ethernet_plugged || netInfo.wifi_plugged 
            })
        })
    }

    function runAppByName(name) {
		hcap.preloadedApplication.getPreloadedApplicationList({
			'onSuccess':function(s) {
				for (var i = 0; i < s.list.length; i++){
					if(s.list[i].title.toLowerCase() == name.toLowerCase()){
                        runApp(s.list[i].id);
					}
				}
			},
			'onFailure':function(f) { console.error('Failed to get Apps list, ' + f.errorMessage) }
		});
    }

    function runApp(id) {
        hcap.preloadedApplication.launchPreloadedApplication({
            'id':id.toString(),
            'onSuccess':function() {},
            'onFailure':function(f) { console.error('Failed to get Apps list, ' + f.errorMessage) }
        });
    }
    
    window.Network = Network;

    if (typeof module === 'object') {
        module.exports = Network;
    }
})();