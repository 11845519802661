;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var device = {
      keys: {}
    };
	var powerState = "Panel On"

    /********************/
    /* Public Functions */
	/********************/
    var Device = {
        // Initialize function
		init: function(callback) {
			this.setKeys()
			setTimeout(setDeviceCapabilities, 100)
      try{
        Raspberry.start(function(res){
          initDeviceStaticParams(function(result){ })
          typeof callback === 'function' && callback(this)
          return this
        }.bind(this)) 
      }
      catch(err){
        setTimeout(function(){
          console.log('retry')
          Device.init(callback)
        },5000)
      }
		},

		checkIn(guest) {
			return true
		},

		checkOut: function() {
			this.clearUserData()
			return true
		},
		
		persistUserData() {
			return true
		},

		clearUserData: function() {
			return true
		},

    keys: function() {
        return device.keys;
		},

		apps: function() {
			return []
		},

		openApps: function() {
      global.vm.$root.visible.apps = true
		},

		openBrowser: function(url) {
      return
		},

		openAirTime: function() {
      return
		},
		
		launchApp: function(appName) {
			console.warn('Launch App is not supported in Raspberry')
			return true
		},
		
		width: function() {
      return window.innerWidth
    },
        
    height: function() {
			return window.innerHeight 
    },
        
    browser: function() {
        return navigator.userAgent;
		},
		
		firmware: function() {
			return Raspberry.firmware();
		},

		vendor: function() {
			return Raspberry.manufacturer();
		},

		model: function() {
			return Raspberry.model();
		},

		serialNumber: function() {
			return Raspberry.serial();
		},

		productCode: function() {
			return 'Unknown';
		},

		country: function() {
			var country = null;

			try {
				country = String(window.navigator.language).toLowerCase().split('-')[1];
			} catch (e) {

			}

			return country;
		},

		language: function() {
			var language = null;

			try {
				language = String(window.navigator.language).toLowerCase();
			} catch (e) {

			}

			return language;
		},

		date: function() {
			return new Date();
		},

		timeZoneOffset: function() {
			return new Date().getTimezoneOffset();
		},

		clearHistory: function() {
			return null;
		},

		pushHistory: function() {
			return null;
		},

		exit: function(dvb) {
			return null;
		},
		
		volume: function(volume) {
			EventBus.$emit('deviceDataChanged', {'volume': volume});
            return 0
        },
        mute: function(mute) {
            return false
		},

		remote: function(key) {
      console.log(key)
			global.vm.$root.triggerKeyboardEvent(document, key)
		},

		updateConfiguration: function(config) {
			return true
		},

		// source is provided as "TV", "HDMI 1", etc
		source: function(source) {
      return false
		},
		
		power: function(state) {
      console.log('state '+state)
			if(state === undefined) {
        return Raspberry.getPowerStatus()
      }
			if(state=='on'){
        console.log('HDMI CEC send on command: '+Raspberry.powerOn())
        powerState='Panel On'
        EventBus.$emit('powerStateChanged', powerState)
      }
      if(state=='off'){
        console.log('HDMI CEC send off command: '+Raspberry.powerOff())
        powerState='Stand By'
        EventBus.$emit('powerStateChanged', powerState)
      }
      if(state=='reload'){
        console.log('HDMI CEC send reboot: '+Raspberry.reboot())
      }
    },
		
		setKeys: function() {
      device.keys = {
				RIGHT: 39,
				LEFT: 37,
				UP: 38,
				DOWN: 40,
				RETURN: 8,
				EXIT: 27,
				ENTER: 13,
				HOME: 36,
				PLAY: 415,
				PAUSE: 19,
				STOP: 413,
				FF: 417,
				RW: 412,
				RED: 90,
				GREEN: 88,
				YELLOW: 67,
				BLUE: 86,
				ZERO: 96,
				ONE: 97,
				TWO: 98,
				THREE: 99,
				FOUR: 100,
				FIVE: 101,
				SIX: 102,
				SEVEN: 103,
				EIGHT: 104,
				NINE: 105,
				PUP: 33,
				PDOWN: 34,
				VUP: 107,
				VDOWN: 109,
				CHLIST: 35,		// END
				GUIDE: 45,		// Insert
				PRECH: 46, 		// Delete
				TXTMIX: 110 	// ,Del
			}
		}
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
	
	window.Device = Device;
	
	function initDeviceStaticParams(callback) {
		var deviceInfo = { }

		deviceInfo['state'] = Device.power()
		deviceInfo['vendor'] = Device.vendor()
		deviceInfo['browser'] = Device.browser()
		deviceInfo['firmware'] = Device.firmware()
		deviceInfo['model'] = Device.model()
		deviceInfo['serial'] = Device.serialNumber()
		EventBus.$emit('deviceDataChanged', deviceInfo)
		callback(true)
	}

	function fireKey(el,key)
	{
		if(document.createEventObject)
		{
			var eventObj = document.createEventObject();
			eventObj.keyCode = key;
			el.fireEvent("onkeydown", eventObj);
			eventObj.keyCode = key;   
		}else if(document.createEvent)
		{
			var eventObj = document.createEvent("Events");
			eventObj.initEvent("keydown", true, true);
			eventObj.which = key; 
			eventObj.keyCode = key;
			el.dispatchEvent(eventObj);
		}
	} 

	function setDeviceCapabilities() {
		global.vm.$root.capabilities.css.filterBlur = true
		global.vm.$root.capabilities.css.svgBlur = true
	}

    if (typeof module === 'object') {
        module.exports = Device;
    }
})();