<template>
  <div :style="makePageFullScreen()" v-if="active">
    <img class="logo" :style="placeLogoAtTheCenter()" src="./../assets/logo.png" />
    <div class="loader" :style="placeLoaderAtTheCenter()"></div>
  </div>
</template>

<script>
//import { EventBus } from '../plugins/eventPlugin';

export default {
  name: 'Loading',
  props:{
    loading:Boolean
  },
  components: {
    
  },
  data() {
    return {
      userAgent:navigator.userAgent,
      width:window.innerWidth,
      height:window.innerHeight,
      active:true,
      network:{},
      device:{},
      storage:{}
    }
  },
  mounted(){
    this.active=this.loading
    console.log('showing loading page')
    /*EventBus.$on('appMounted', () => {
      this.network=window.Network
      this.device=window.Device
      this.storage=window.Storage
    })*/
    
  },
  methods:{
    makePageFullScreen(){
      var css='background-color:#262020;'
      css+='width:'+this.width+'px;'
      css+='height:'+this.height+'px;'
      css+='color:white;'
      return css
    },
    placeLogoAtTheCenter(){
      const logoSize = Math.round(Math.min(this.width, this.height) / 12)
      let loaderCss = 'position:absolute;width:'+logoSize+'px;height:'+logoSize+'px;'
      loaderCss+='top:'+(this.height-logoSize)/2+'px;'
      loaderCss+='left:'+(this.width-logoSize)/2+'px;'
      return loaderCss
    },
    placeLoaderAtTheCenter(){
      const loaderSize = Math.round(Math.min(this.width, this.height) / 8)
      let loaderCss = 'position:absolute;width:'+loaderSize+'px;height:'+loaderSize+'px;'
      loaderCss+='top:'+((this.height-loaderSize)/2-4)+'px;'
      loaderCss+='left:'+((this.width-loaderSize)/2-4)+'px;'
      return loaderCss
    }
  }, 

}
</script>

<style scoped>
.bg{
  background-color: #262020;
}

.loader {
  border: 4px solid #262020; 
  border-top: 4px solid #fff; 
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

