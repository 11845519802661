;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var storage = {
    };
    // Firmware 03.11.60.01 LG slow navigation in channel list
    // Invalid IP Server address type

    var serIP = '127.0.0.1'
    var serPort = 8081
    var production = process.env.NODE_ENV === 'production'

    /********************/
    /* Public Functions */
	/********************/
    var Storage = {
        // Initialize function
		init(callback) {
            var self = this
            hcap.system.getProcentricServer({
                'onSuccess': function(s) { 
                    serIP = s.ipServer.type == 'ip' ? s.ipServer.ip : s.ipServer.domainName
                    serPort = s.ipServer.port
                    if (callback) return callback(self);
                },
                'onFailure': function(f) { 
                    // In case of old firmware LG throws "Invalid IP Server address type"
                    serIP = window.location.hostname
                    serPort = window.location.port === "" ? 80 : window.location.port

                    console.error('INIT Storage Failed: ' + f.errorMessage) 
                    if (callback) return callback(self);
                }
            })
        },

        // Set value to the storage
        // PARAMS: {String} name, {Object/String/Number} value
        // RETURNS: {Boolean}
        set: function(name, value) {
            if (window.localStorage) {
                return window.localStorage.setItem(name, JSON.stringify(value));
            }

            return false;
        },
        
        // Get value from the storage
        // PARAMS: {String} name
        // RETURNS: {Object/String/Number} or FALSE 
        get: function(name) {
            var value;

            if (window.localStorage) {
                value = window.localStorage.getItem(name);
                if(typeof value !== 'undefined'){
                    try{
                        return JSON.parse(value);
                    }
                    catch(e){
                        return value;
                    }
                }
            }

            return false;
        },

        // Clear all stored data
        // RETURNS: {Boolean}
        clear: function(){
            if(window.localStorage){
                return window.localStorage.clear();
            }
            return false;
        },

        offlineMode() { return false },
        serverHost() { return serIP || this.get('serverHost') },
        serverPort() { return serPort || this.get('serverPort') },
        serverProtocol() { return 'http:' },        // LG currently only http, we do not have a way to get the protocol from hcap
        serverURL() { return this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() },
        mobilePath() { return !production ? this.serverProtocol() + '//' + this.serverHost() + ':8082' : this.serverURL() + '/mobile' },
        assetsPath() { return this.serverURL() + '/contents/' },
        proxyPath() { return this.serverProtocol() + '//' + this.serverHost() + ':1' + this.serverPort() + '/fetch/' },

        // Handles downloading application to local storage for offline operation
        // RETURNS: {Boolean}
        storeAppOffline: function(callback) {
            callback(null, true)
        },

        // RETURNS: {Boolean}
        removeAppOffline: function() {
        },

        // Handles downloading project static assets to local storage for offline operation
        // RETURNS: {Boolean}
        storeProjectDataOffline: function(projectObj, callback) {
            callback(null, true)
        },
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
    
    window.ScreenStorage = Storage;

    if (typeof module === 'object') {
        module.exports = ScreenStorage;
    }
})();