;(function($) {
    'use strict';
    /* Global Configuration */
    var storage = {};
    var data = {}		
    var downloadQueue = []
    var production = process.env.NODE_ENV === 'production'

    /* Public Functions */
    var Storage = {
        // Initialize function
		init: function(callback) {
        if (callback) {
          return callback(this);
        }
    },

    set: function(name, value) {
      if (window.localStorage) {
        return window.localStorage.setItem(name, JSON.stringify(value))
      }
      return false
    },

    get: function(name) {
      var value;
      if (window.localStorage) {
        value = window.localStorage.getItem(name)
        if(typeof value !== 'undefined'){
          try{
              return JSON.parse(value)
          }
          catch(e){
              return value
          }
        }
      }
      return false
    },

    save: function(id, size, name){
      return true
    },

    delete: function(id, size, name){
      return true
    },
    
    used: function(){
      var usedStorage = android.getUsedStorage()
      //console.log('used memory '+usedStorage)
      return usedStorage
    },

    total: function(){
      var totalStorage= android.getTotalStorage()
      //console.log('total memory '+totalStorage)
      return totalStorage
    },

    clear: function(){
      if(window.localStorage){
        return window.localStorage.clear()
      }
      return false
    },

    offlineMode() {
      try{
        return android.getOfllineModeStatus()
      }
      catch(error){
        return false
      }
    },
    

  }
  window.ScreenStorage = Storage

  if (typeof module === 'object') {
    module.exports = ScreenStorage
  }
})()

   /* //#region Low Level Storage Functions

    // Set value to the storage
    // PARAMS: {String} name, {Object/String/Number} value
    // RETURNS: {Boolean}
    set: function(name, value) {
        data[name] = value;
      return writeData(data);
    },
    
    // Get value from the storage
    // PARAMS: {String} name
    // RETURNS: {Object/String/Number} or FALSE 
    get: function(name) {
        return data[name];
    },

    // Clear all stored data
    // RETURNS: {Boolean}
    clear: function(){
        data = {};
      return writeData(data);
    },

    // Create folder
    // RETURNS: {Boolean}
    createDir: function(path) {
        return android.createDir(path);
    },

    // Delete folder
    // RETURNS: {Boolean}
    deleteDir: function(path) {
        return android.removeDir(path);
    },

    // Delete file
    // RETURNS: {Boolean}
    deleteFile: function(path) {
        return android.removeFile(path)
    },

    // Check if folder exists
    // RETURNS: {Boolean}
    dirExists: function(path) {
        return false
    },

    // Check if file exists
    // RETURNS: {Boolean}
    fileExists: function(path) {
        return android.fileExists(path)
    },

    //#endregion Low Level Storage Functions

    offlineMode() {
        try{
            return android.getOfllineModeStatus()
        }
        catch(error){
            return false
        }
		},
    
    serverHost() { return window.location.hostname || this.get('serverHost') },
    serverPort() { return !production ? 80 : window.location.port || this.get('serverPort') || 80 },
    serverProtocol() { return window.location.protocol !== 'file:' ? window.location.protocol || this.get('serverProtocol') : this.get('serverProtocol')},
    serverURL() { return !production ? this.serverProtocol() + '//' + this.serverHost() : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() },
    mobilePath() { return !production ? this.serverProtocol() + '//' + this.serverHost() + ':8082' : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() + '/mobile' },
    assetsPath() { return this.serverURL() + '/contents/' },
    proxyPath() { return this.serverProtocol() + '//' + this.serverHost() + ':1' + this.serverPort() + '/fetch/' },

    // Handles downloading application to local storage for offline operation
    // RETURNS: {Boolean}
    storeAppOffline: function(callback) {
        downloadQueue.push({src: this.serverURL() + '/tv/index.html', dst: 'offline.html', filename: 'offline.html', isApp: true })
        downloadQueue.push({src: this.serverURL() + '/tv/build.js', dst: 'build.js', filename: 'build.js', isApp: true })
        downloadQueue.push({src: this.serverURL() + '/tv/worker.js', dst: 'worker.js', filename: 'worker.js', isApp: true })
        downloadFromQueue()
        callback(null, true)
    },

    // RETURNS: {Boolean}
    removeAppOffline: function() {
        this.deleteFile('offline.html')
        this.deleteFile('build.js')
        this.deleteFile('worker.js')
    },

    // Handles downloading project static assets to local storage for offline operation
    // RETURNS: {Boolean}
    storeProjectDataOffline: function(projectObj, callback) {
        callback(null, true)
    },
    }
        
    // Private Functions 

    // Get data from the Android File System
    function readData() {
        try {
            if(android.fileExists('hoteplusData')) {
                return JSON.parse(android.readFile('hoteplusData'))
            }
            else return {}
        } 
        catch (e) { 
            console.log('Error reading storage data, ' + e)
            return {} 
        }
    }
    
    // Store data to the Android File System
    // PRIVATE
    function writeData(data) {
        try {
            if(!android.fileExists('hoteplusData')) android.createFile('hoteplusData')
            return android.writeFile('hoteplusData', JSON.stringify(data))
        } 
        catch (e) { 
            console.log('Error writing storage data, ' + e)
            return false; 
        }
    }

    function downloadFromQueue() {
        if(downloadQueue.length === 0) return
        var asset = downloadQueue.shift()
        if(typeof asset !== 'object' || asset === null || !('src' in asset) || !('dst' in asset) || !('filename' in asset) || !('isApp' in asset)) {
            console.error('Asset:' + asset + ' is wrong, skiping...')
            return setTimeout(function() { downloadFromQueue() }, 100)
        }
        downloadFile(asset.src, asset.dst, function(result) {
            if(!result) {
                console.error('Failed to download ' + asset.filename)
            }
            else { 
                if(!asset.isApp) {
                    var storedAssets = Storage.get('projectAssets')
                    storedAssets.push(asset.filename)
                    Storage.set('projectAssets', storedAssets)
                    console.info('Downloaded: ' + asset.filename)
                }
                else console.warn('Downloaded: ' + asset.filename)
            }
            setTimeout(function() { downloadFromQueue() }, 100)
        })
    }

    // Downloads file to local storage
    // RETURNS: {Boolean}
    function downloadFile(src, dst, callback) {
        return callback(android.downloadFile(src, dst))
    }

    window.ScreenStorage = Storage;

    if (typeof module === 'object') {
        module.exports = Storage;
    }
})();*/