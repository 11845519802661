;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var rebootTimeout = {}
    var device = {
		keys: {}
    };
    var powerStates = { 
        'Standby': 'Stand By',
        'Normal': 'Panel On'
    };
    var scriptsLoading = 0
    var appStartedTime = 0      // TIZEN BLUETOOTH HACK EJ

    /********************/
    /* Public Functions */
    /********************/
    var Device = {
        // Initialize function
        init: function(callback) {
            var self = this

            // Load TIZEN API JS Files from TV
            loadAPI(function(error, result) {
                if(error) {
                    console.error(error)
                    return callback(false)
                }

                registerKeys()
                setKeys();
                
                // Add power state change listener
                if('b2bpower' in b2bapis) {
                    try { 
                        b2bapis.b2bpower.setPowerStateChangeListener(powerStateChanged) 
                        setTimeout(function() { EventBus.$emit('powerStateChanged', powerStates[b2bapis.b2bpower.getPowerState()]); }, 2000)     // get initial power state
                    }
                    catch(e) { console.error(e.message) }
                }
                else {
                    console.error('Power State Listener is not supported, cannot inform for power state changes')
                    setTimeout(function() { EventBus.$emit('powerStateChanged', 'Panel On') }, 2000)       // on signage monitors make it Panel On in order to play the Slideshow and Videos
                }

                // Applications enter or exit notification (enter app = true, exit app = false)
                document.addEventListener('visibilitychange', function() {
                    appStartedTime = Date.now()     // TIZEN BLUETOOTH HACK EJ
                    if(document.hidden) { } 
                    else global.vm.$root.visible.project = true     // enable project
                });

                initDeviceStaticParams(function(result){ })
                self.serviceLock(true)
                setTimeout(setDeviceCapabilities, 100)
                
                return callback(self || this);
            })
        },

        // De Initialize function
        deInit: function(callback) {
        },

        serviceLock: function(state) {
			// NOT NEEDED
        },
        
        checkIn(guest) {
			return true
		},

        checkOut: function() {
			this.clearUserData()
			return true
        },
        
        persistUserData() {
			return true
		},

		clearUserData: function() {
            // b2bapis.b2bcontrol.deleteAppPrivateInfo()
			return true
		},

        exit: function(dvb) {
            return true
        },

        apps: function() {
			return []
		},

		openApps: function() {
            this.launchApp("applications")
		},
        
        openBrowser: function(urls) {
            this.launchApp("browser", urls)
        },

        openAirTime: function() {
            /*tizen.application.getAppsInfo(function(applications) {
                var apps = []
                for (var i = 0; i < applications.length; i++) {
                    apps.push(applications[i].id);
                }
                console.log(apps)
            }); */
            // New Tizen AppId
            tizen.application.launch("org.tizen.airwave-app", 
                function() { console.log('Started Airtime Application') }, 
                function() {
                    // old Tizen AppId
                    tizen.application.launch("3201804016036", 
                        function() { console.log('Started Airtime Application') }, 
                        function() { 
                            // Newer Tizen AppId
                            tizen.application.launch("34r7A9IqwB.airwave", 
                                function() { console.log('Started Airtime Application') }, 
                                function(e) { console.error('Start Airtime app failed: ' + e.message) }
                            )
                        }
                    )
                }
            )
        },

        launchApp: function(appName = null, pars = undefined) {
            // TIZEN HACK, EJ re-enters bluetooth app when exiting, so do not allow shorted from 0.5s to re-enter apps
            if(Date.now() < appStartedTime + 500) {
                global.vm.$root.visible.project = true
                return console.error('Fast APP opening is not allowed, please try again')
            }

            try {
                var appControl;
                if(appName == "applications") {    // SmartHub
                    if(Number(this.OSversion()) >= 6) {
                        appName = "com.samsung.tv.store"
                        appControl = new tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, [ new tizen.ApplicationControlData("__K_FG_LAUNCH", ["enable"]) ]);
                    }
                    else if(Number(this.OSversion()) == 4) {
                        appName = "com.samsung.tv.store"
                        appControl = new tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, [ new tizen.ApplicationControlData("Sub_Menu", ["hotel"]) ]);
                    }
                    else {
                        appName = "org.volt.apps"
                        appControl = new  tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, null);
                    }                    
                } 
                else if(appName == "screenMirroring") { // Screen Mirroring
                    appName = "org.tizen.connection-guide-app"
                    // From AU800 (tizen 6) the ScreenMirroring application name changed
                    if((Number(this.OSversion()) < 6)) appControl = new tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, [ new tizen.ApplicationControlData("deeplink", ["mobile_screenmirroring_1"]) ]);
                    else appControl = new tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, [ new tizen.ApplicationControlData("deeplink", ["smartphone_screenmirroring_1"]) ]);
                }
                else if(appName == "browser") {     // Web Browser go to site
                    if(!pars) pars = 'https://www.google.com'
                    appName = null
                    appControl = new tizen.ApplicationControl('http://tizen.org/appcontrol/operation/view', pars, null, null, null);
                }
                else if(appName == "bluetooth") {    // Bluetooth
                    if((Number(this.OSversion()) < 6)) {
                        appName = "org.tizen.ep-hotel-btplayer"
                        appControl = new  tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, null);
                    }
                    else {
                        appName = "org.tizen.ep-hotel-popup-ui"
                        appControl = new  tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, [new tizen.ApplicationControlData("uri", ["SoundMirroringGuide"])]);
                    }
                }
                else {  // rest
                    appControl = new  tizen.ApplicationControl("http://tizen.org/appcontrol/operation/view", null, null, null, null);
                }

                var appControlReplyCallback = {
                    onsuccess: function(data) { console.log("inside success"); },
                    onfailure: function(e) { console.error("Failed to launch APP: " + e.message); return false }
                };

                tizen.application.launchAppControl(appControl, appName, 
                    function() { console.log("APP: " + appName + ' Started'); return true }, 
                    function(e) { console.error("Failed to launch APP: " + e.message); return false }, 
                    appControlReplyCallback
                );
            } 
            catch (error) { console.error("Failed to launch APP: " + error.message); return false }
        },

        keys: function() {
            return device.keys;
        },

		width: function() {
			return window.screen.width
        },
        
        height: function() {
			return window.screen.height
        },

        browser: function() {
            return navigator.userAgent;
        },

        firmware: function() {
            return b2bapis.b2bcontrol.getFirmwareVersion() || 'Unknown';
        },

        OSversion: function() {
            return tizen.systeminfo.getCapability("http://tizen.org/feature/platform.version")
        },

        vendor: function() {
			return 'Tizen';
        },

        serialNumber: function() {
			return b2bapis.b2bcontrol.getSerialNumber()
		},
        
        year: function() { return 1 },
        
        productCode: function() {
            return 'Unknown';
        },

        model: function() {
            return webapis.productinfo.getRealModel() || 'Unknown';
        },
        
        country: function() {
            var country = null;

            try { country = String(window.location.search.match(/country=([\w\-\_]+)/)[1]).toLowerCase(); }
            catch (e) { }

            return country;
        },
        
        language: function() {
            var language = null;

            try { language = String(window.location.search.match(/lang=([\w\-\_]+)/)[1]).toLowerCase(); } 
            catch (e) { }

            return language;
        },
        
        date: function() {
            return new Date();
        },
        
        timeZoneOffset: function() {
            return new Date().getTimezoneOffset();
        },

        volume: function(volume) {
            if (tizen) {
                if(volume === undefined) volume = tizen.tvaudiocontrol.getVolume()
                else {
                    tizen.tvaudiocontrol.setMute(false)
                    tizen.tvaudiocontrol.setVolume(volume)
                    volume = tizen.tvaudiocontrol.getVolume()
                    EventBus.$emit('deviceDataChanged', {'volume': volume});
                }
                return volume
            }
            return 0
        },

        mute: function(mute) {
            if (tizen) {
                tizen.tvaudiocontrol.setMute(mute)
                return true
            }
            return false
        },
        
        remote: function(key) {
            if(key === 'POWER') {
                if(this.power() === 'Panel On') this.power('off')
                else this.power('on')
                return 
            }
            // In Tizen we cannot emulate keypress in API so...
            var event = document.createEventObject ? document.createEventObject() : document.createEvent("Events");
            event.keyCode = key;
            event.which = key;
            EventBus.$emit('keyDown', event)
            global.vm.$root.keyPressed(event)
        },

        updateHoteplusApp: function() {
			return true
        },
        
        updateConfiguration: function(config) {
			return true
        },
        
        // source is provided as "TV", "HDMI 1", etc
		source: function(source) {
            if(source === undefined) return tizen.tvwindow.getSource().type
            tizen.systeminfo.getPropertyValue(
                "VIDEOSOURCE", 
                function(sources) {
                    var allSources = sources.connected.concat(sources.disconnected)
                    var dstSource = {}
                    for(var i = 0; i < allSources.length; i++) {
                        if(source === 'TV' && allSources[i].type == 'TV') dstSource = allSources[i]
                        else if(source === 'HDMI 1' && allSources[i].type == 'HDMI' && allSources[i].number === 1) dstSource = allSources[i]
                        else if(source === 'HDMI 2' && allSources[i].type == 'HDMI' && allSources[i].number === 2) dstSource = allSources[i]
                        else if(source === 'HDMI 3' && allSources[i].type == 'HDMI' && allSources[i].number === 3) dstSource = allSources[i]
                    }
                    if(dstSource === {}) {
                        console.error('Change source failed, not available source found!')
                        return false 
                    }

                    if(source === 'TV') { 
                        tizen.tvwindow.hide(function() {}, function() {}, "MAIN") 
                        console.log('Changed source to ' + source); 
                        return true 
                    }
                    else {
                        tizen.tvwindow.setSource(
                            dstSource,
                            function() { 
                                tizen.tvwindow.show(function(){}, null, ["0", "0", "100%", "100%"], "MAIN");
                                console.log('Changed source to ' + source); 
                                return true 
                            },
                            function(e) { console.error('Change source failed '+ e.message); return false }
                        );
                    }
                }, 
                function(err) { 
                    console.error('Failed to get property VIDEOSOURCE')
                    return false 
                }
            )
		},

        power: function(state) {
            var result = true;

            if(state === undefined) {
                if('b2bpower' in b2bapis) result = powerStates[b2bapis.b2bpower.getPowerState()];
                else result = 'Panel On'
            }
            else if(state.toLowerCase() === 'on') {
                if('b2bpower' in b2bapis) {
                    b2bapis.b2bpower.setPowerOn(
                        function() { return true },
                        function(err) { console.error('Power On failed, error ' + err.message); return false}
                    )
                }
                else {
                    console.error('Power On is not supported on this Tizen version')
                    return false
                }
            }
            else if(state.toLowerCase() === 'off') {
                b2bapis.b2bcontrol.setPowerOff(
                    function(){ return true },
                    function(err) { console.error('Power Off failed, error ' + err.message); return false}
                )
            }
            else if(state.toLowerCase() === 'reboot') {
                b2bapis.b2bcontrol.rebootDevice(
                    function(){ return true },
                    function(err) { console.error('Reboot failed, error ' + err.message); return false}
                )
            }
            else if(state.toLowerCase() === 'reload') {
                b2bapis.b2bcontrol.rebootDevice(
                    function(){ return true },
                    function(err) { console.error('Reboot failed, error ' + err.message); return false}
                )
            }
            else result = false

            return result
        }
    }

    /*********************/
    /* Private Functions */
    /*********************/

    function loadAPI(callback) {
        loadJS('$B2BAPIS/b2bapis/b2bapis.js', function(error, result) {
            if(error) return callback(error, false)
            loadJS('$WEBAPIS/webapis/webapis.js', function(error, result) {
                if(error) return callback(error, false)
                // The below throws error on SSSP4
                loadJS('$B2BAPIS/b2bapis/b2bavplay.js', function(error, result) {
                    return error ? callback(error, false) : callback(null, true)
                });
            });
        });
    }

    function loadJS(src, callback) {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.language = 'javascript';
        script.src = src;
        script.onload = function () {
            if(--scriptsLoading == 0) return callback(null, true)
        };
        script.onerror = function (e) {
            if(--scriptsLoading == 0) return callback(null, true)
        };
        scriptsLoading++;
        head.appendChild(script)
    };

    function setKeys() {
        device.keys = {
            POWER: "POWER",
            RIGHT: 39,
            LEFT: 37,
            UP: 38,
            DOWN: 40,
            ENTER: 13,
            RETURN: 10009,
            HOME: 10071,
            ZERO: 48,
            ONE: 49,
            TWO: 50,
            THREE: 51,
            FOUR: 52,
            FIVE: 53,
            SIX: 54,
            SEVEN: 55,
            EIGHT: 56,
            NINE: 57,
            RED: 403,
            GREEN: 404,
            YELLOW: 405,
            BLUE: 406,
            PLAY: 415,
            PAUSE: 19,
            STOP: 413,
            REC: 416,
            FF: 417,
            RW: 412,
            TOOLS: 10135,
            PUP: 427,
            PDOWN: 428,
            VUP: 447,
            VDOWN: 448,
            MUTE: 449,
            CHLIST: 10073,
            SOURCE: 10072,
            GUIDE: 458,
            PRECH: 10190,
            TXTMIX: 10225,
            //FAVCH: tvKey.KEY_FAVCH,
            EXIT: 10182,
            INFO: 457
        };
    };

    function initDeviceStaticParams(callback) {
        EventBus.$emit('deviceDataChanged', {
            'volume': Device.volume(),
            'vendor': Device.vendor(),
            'browser': Device.browser(),
            'firmware': Device.firmware(),
            'model': Device.model(),
            'serial': Device.serialNumber()
        })
        callback(true)
    };

    function registerKeys() {
        var keys = tizen.tvinputdevice.getSupportedKeys()
        for(var i = 0; i < keys.length; i++) {
            tizen.tvinputdevice.registerKey(keys[i].name)
        }
        // unregister Source key
        tizen.tvinputdevice.unregisterKey('Source')

        // register Special Keys
        var specialKeysObj = [ ['SmartHub'], ['Content'] ]      // SmartHub and Home special keys
        for(var i = 0; i < specialKeysObj.length; i++){
            webapis.appcommon.registerKey(specialKeysObj[i]);  
        }
    }

    function powerStateChanged(state) {
        console.warn('Power State Changed: ' + state.data)
        if(state.data != 'Normal' && state.data != 'Standby') return

        // simulate "Reboot Time 1sec" when StandBy
        if(state.data == 'Standby') {
            clearTimeout(rebootTimeout)
            // Below is disabled because it interfere with Firmware Update
            if(global.vm.$root.settings.vReboot) rebootTimeout = setTimeout(Device.power('reload'), 1000)
        }
        else clearTimeout(rebootTimeout)

        EventBus.$emit('powerStateChanged', powerStates[state.data]); 
    }

    function setDeviceCapabilities() {
        global.vm.$root.capabilities.css.filterBlur = true
        global.vm.$root.capabilities.css.svgBlur = true
	}

    window.Device = Device;

    if (typeof module === 'object') {
        module.exports = Device;
    }
})();