;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var network = { }
    var mirroringRequested = false
    var wifiShouldBeOn = false

    /********************/
    /* Public Functions */
	/********************/
    var Network = {
        // Initialize function
		init: function(callback) {
            var self = this
            if(!Device.NIC || !Device.NETWORK) return callback('Unable to load the network API', null);

            // Start Network Events
            NETWORKEVENT.Open("Network", "1.000","Network");
            NETWORKEVENT.OnEvent = NetworkEventHander

            initNetworkParams(function(error, net) {
                if(error) console.error('Could not initialize network ' + error)
                callback(null, self)
            })
        },

        openNetflix(reason) {
            console.error('Netflix is not available in this device')
            return global.vm.$root.$notify("Netflix is not available in this device")
        },

        openBluetoothPlayer: function() {
            Device.NETWORK.Open("HOTEL","1.000","HOTEL");
            Device.NETWORK.Execute("RunHotelApp","BtApp","1");
            Device.NETWORK.Close();
        },

        openScreenMirroring: function() {
            if(this.softApEnabled()) {
                this.softApEnabled(false)
                wifiShouldBeOn = true
            }
            mirroringRequested = true
            Device.NETWORK.Open("TaskManager","1.000","TaskManager");
            Device.NETWORK.Execute("RunWIFIDisplay");
            Device.NETWORK.Close();
        },

        // return  1 = Wired || 0 = Wireless
        activeNic: function() {
            var nic = Device.NIC.GetActiveType();
            if(nic == 1) return 1;
            else if (nic == 0) return 2;
            else return -1;
        },

        // Get/Set the IP DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        ipDhcpEnabled: function(mode = '-1') {
            var nic = Device.NIC.GetActiveType();
            // SET
            if(mode == true || mode == false ){
                var result = '';
                if(mode == true) result = Device.NIC.SetIPMode(nic, 0);
                else if(mode == false) result = Device.NIC.SetIPMode(nic, 1);
                if(result == 1) return true;
                else return false;
            } 

            // GET
            if (Device.NIC.GetIPMode(nic) == 0) return true;
            else return false;
        },

        // Get/Set the DNS DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        dnsDhcpEnabled: function(mode = '-1') {
            var nic = Device.NIC.GetActiveType();
            // SET
            if(mode == true || mode == false ){
                var result = '';
                if(mode == true) result = Device.NIC.SetDNSMode(nic, 0);
                else if(mode == false) result = Device.NIC.SetDNSMode(nic, 1);
                if(result == 1) return true;
                else return false;
            } 

            // GET
            var disabled = Device.NIC.GetDNSMode(nic);
            if (disabled == 0) return true;
            else return false;
        },

        mediaConnected: function() {
            var nic = Device.NIC.GetActiveType();
            if (Device.NIC.CheckPhysicalConnection(nic) == 1) return true;
            else return false;
        },

        gatewayReachable: function() {
            var nic = Device.NIC.GetActiveType();
            if (Device.NIC.CheckGateway(nic) == 1) return true;
            else return false;
        },
        
        dnsReachable: function() {
            var nic = Device.NIC.GetActiveType();
            if (Device.NIC.CheckDNS(nic) == 1) return true;
            else return false;
        },
        
        hasInternet: function() {
            var nic = Device.NIC.GetActiveType();
            if (Device.NIC.CheckHTTP(nic) == 1) return true;
            else return false;
        },
        
        // Returns Ethernet MAC
        MAC: function() {
            var mac = Device.NIC.GetMAC(1).toUpperCase();
            if (mac) return mac;
            else return 'Unknown';
        },

        // Returns Wireless MAC
        MACw: function() {
            var mac = Device.NIC.GetMAC(0).toUpperCase();
            if (mac) return mac;
            else return 'Unknown';
        },

        IP: function() {
            var nic = Device.NIC.GetActiveType();
            var ip = Device.NIC.GetIP(nic);
            if (ip) return ip;
            else return '0.0.0.0';
        },

        GW: function() {
            var nic = Device.NIC.GetActiveType();
            var gw = Device.NIC.GetGateway(nic);
            if (gw) return gw;
            else return '0.0.0.0';
        },

        SM: function() {
            var nic = Device.NIC.GetActiveType();
            var sm = Device.NIC.GetNetMask(nic);
            if (sm) return sm;
            else return '0.0.0.0';
        },

        DNS: function() {
            var nic = Device.NIC.GetActiveType();
            var dns = Device.NIC.GetDNS(nic);
            if (dns) return dns;
            else return '0.0.0.0';
        },

        // SoftAp Functions
        //
        // Get/Set the Soft AP state
        softApEnabled(enable = '-1'){
            Device.NETWORK.Open("Network","1.000","Network");
            var result = '';
            
            // SET
            if(enable == true || enable == false ){
                if(enable == true) result = Device.NETWORK.Execute("EnableSoftAP");
                else if(enable == false) result = Device.NETWORK.Execute("DisableSoftAP");
                if(result == 1) {
                    result = true;
                    EventBus.$emit('deviceDataChanged', { 
                        'apStatus': enable,
                        'apSsid': this.SSID(),
                        'apPass': this.softApPass(),
                    })
                } 
                else result = false;
            } 
            // GET
            else {
                if(Device.NETWORK.Execute("IsSoftAPEnabled") === 1) result = true;
                else result = false;
            }
            Device.NETWORK.Close();

            return result
        },

        // Get/Set the SSID name
        // returns SSID on Get / true or false on Set
        SSID(name = null){
            Device.NETWORK.Open("Network","1.000","Network");
            var result = ''

            // SET
            if(name !== null){
                result = Device.NETWORK.Execute("SetTVName", name);
                if(result == 0) result = false;
                else {
                    EventBus.$emit('deviceDataChanged', { 'apSsid': name })
                    result = true;
                }
            } 
            // GET
            else {
                result = Device.NETWORK.Execute("GetSoftAPSSID");
            }
            Device.NETWORK.Close();

            return result
        },

        // Get the Soft AP password
        softApPass() {
            Device.NETWORK.Open("Network","1.000","Network");
            var result = ''

            // GET
            result = Device.NETWORK.Execute("GetSoftAPSecurityKey");
            if(result == 0) result = false;

            Device.NETWORK.Close();

            return result
        },

        // Enable or disable the auto regeneration of password at reboot
        softApAutoResetPass(enable = -1) {
            if(enable !== true && enable !== false) {
                console.error('Wrong input for softApAutoResetPass, should be true or false')
                return false;
            }

            Device.NETWORK.Open("TVMW",1.0,"TVMW");
            var result = ''

            if(enable) result = Device.NETWORK.Execute("SetSoftAPSecurityKeyAutoGeneration", 1);
            else result = Device.NETWORK.Execute("SetSoftAPSecurityKeyAutoGeneration", 0);
            if(result == 0) result = false;

            Device.NETWORK.Close();

            return result
        },

        // Get the Soft AP Strength (returns 1-5)
        // Set (input 1-5, returns true/false)
        softApStrength(strength = 0) {
            Device.NETWORK.Open("Network","1.000","Network");
            var result = ''

            // SET
            if(strength == 1 || strength == 2 || strength == 3 || strength == 4 || strength == 5) {
                result = Device.NETWORK.Execute("SetSoftAPSignalStrength", 5 - strength + 1); // reverse because Samsung's strength 1 = 100% and 5 = 20%
                if(result == 0) result = false;
                else {
                    Storage.set('softApStrength', strength);
                    EventBus.$emit('deviceDataChanged', { 'apStrength': strength });
                    result = true
                }
            }
            // GET
            else {
                result = Storage.get('softApStrength');
                if(!result) result = 5;
            }

            Device.NETWORK.Close();

            return result
        },
        
        // Get the Soft AP Channel (returns number)
        // Set (input 1-12, returns true/false)
        softApChannel(ch = 0) {
            Device.NETWORK.Open("Network","1.000","Network");
            var result = ''

            // SET
            // find something better here, maybe Number.isInteger with polyfill and then (ch >= 1 && ch <= 12) 
            if(ch == 1 || ch == 2 || ch == 3 || ch == 4 || ch == 5 || ch == 6 || ch == 7 || ch == 8 || ch == 9 || ch == 10 || ch == 11 || ch == 12) {
                result = Device.NETWORK.Execute("SetSoftAPChannel", ch);
                if(result == 0) result = false;
                else {
                    Storage.set('softApChannel', ch);
                    EventBus.$emit('deviceDataChanged', { 'apChannel': ch });
                    result = true
                }
            }
            // GET
            else {
                result = Storage.get('softApChannel');
                if(!result) result = 1;
            }
            Device.NETWORK.Close();

            return result
        }
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
    function initNetworkParams(callback) {
        var ip = Network.IP()
        if(ip == '0.0.0.0') setTimeout(function() { EventBus.$emit('deviceDataChanged', {'ip': Network.IP()}) }, 30000)     // solve ip 0.0.0.0 issue
        EventBus.$emit('deviceDataChanged', {
            'mac': Network.MAC(),
            'macw': Network.MACw(),
            'nicsCount': '',
            'ip': ip,
            'sm': Network.SM(),
            'gw': Network.GW(),
            'dns1': Network.DNS(),
            'dns2': '',
            'local': Network.gatewayReachable(),
            'internet': Network.hasInternet(),
            'dhcp': Network.ipDhcpEnabled(),
            'mode': Network.activeNic(),
            'physicalCon': Network.mediaConnected(),
            'apStatus': Network.softApEnabled(),
            'apSsid': Network.SSID(),
            'apPass': Network.softApPass(),
            'apStrength': Network.softApStrength(),
            'apChannel': Network.softApChannel()
        })
        callback(null, true)
    }

    function NetworkEventHander(event, data1, data2) {
        //console.log("onEvent="+event+"param1:"+data1+"param2:"+data2)

        switch (event)  {
            case 0:
                console.warn(data1 == 1 ? 'Cable Connected' : 'Cable Disconnected')
                //alert('EVENT_NET_CABLE' + data1);
                break;
            case 1:
                console.warn(data1 == 1 ? 'Wireless Connected' : 'Wireless Disconnected')
                //alert('EVENT_NET_WIRELESS' + data1);
                break;
            case 2:
                //alert('EVENT_NET_STATUS_GATEWAY_CONNECTED' + data1);
                if(data1 == 1) {
                    console.warn('Network connected')
                    EventBus.$emit('deviceDataChanged', { 
                        'local': Network.gatewayReachable(),
                        'internet': Network.hasInternet(),
                        'physicalCon': Network.mediaConnected()
                    })
                }
                if(data1 == 0) {
                    console.warn('Network disconnected')
                    EventBus.$emit('deviceDataChanged', { 
                        'local': Network.gatewayReachable(),
                        'internet': Network.hasInternet(),
                        'physicalCon': Network.mediaConnected(),
                        'mode': Network.activeNic(),
                        'ip': Network.IP(),
                        'sm': Network.SM(),
                        'gw': Network.GW()
                    })
                }
                break;
        }
    }

    window.Network = Network;

    if (typeof module === 'object') {
        module.exports = Network;
    }
})();