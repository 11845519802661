<template>
  <div :style="makePageFullScreen()">
    <div :style="firstDiv()">
      <img class="logo" :style="placeLogo()" src="./../assets/logo.png" />
      
      <div :style="placeHeading()">
        <div><h2>{{registrationInstructions.heading}}</h2></div>
        <div><h1>{{number}}</h1></div>
        <div>
          <h4 v-for="(step,i) in registrationInstructions.steps" :key="i">{{i+1}}. {{step}}</h4>
        </div>
      </div> 
    </div>

    <div :style="secondDiv()">
      <img src="./../assets/background.jpg" :style="secondDivBg()"/>
      <div v-if="registrationInstructions.link.length>10" :style="qrContainer()">
        <qr-code 
          :text="registrationInstructions.link"
          :size="qrSize()"
          color="#fff"
          bg-color="#262020"
          :style="qrCenterInContainer()">
        </qr-code> 
      </div>
    </div> 
  </div>
</template>

<script>
//import { EventBus } from '../plugins/eventPlugin';

export default {
  name: 'Register',
  props:{
    number:String
  },
  components: {
    
  },
  data() {
    return {
      userAgent:navigator.userAgent,
      width:window.innerWidth,
      height:window.innerHeight,
      active:true,
      network:{},
      device:{},
      logoPlace:{
        left:0,
        right:0,
        top:0,
        bottom:0
      },
      registrationInstructions:{
        heading:'Register your device',
        steps:[
          'Visit management.hoteplus.com and login',
          'Go to Screens and select "Add screen"',
          'Type the code to device field and give it a name',
          'Your device is ready to use'
        ],
        link:''
        //link:'https://management.hoteplus.com/registerScreen/?uid='+this.number
      }
      
    }
  },
  mounted(){
    /*this.$socket.on('registered', function(details){
      console.log('associated')
      this.stopRegisterPage()
    }.bind(this))*/
  },
  beforeUnmount(){
    this.$socket.off('registered')
  },
  methods:{
    stopRegisterPage(){
      this.$emit("registerComplete")
    },
    firstDiv(){
      var firstDivCss=''
      if(this.width>this.height){
        firstDivCss='position:absolute;width:'+this.width*0.6+'px;height:'+this.height+'px;'
      }
      else{
        firstDivCss='position:absolute;width:'+this.width+'px;height:'+this.height*0.6+'px;'
      }
      return firstDivCss
    },
    secondDiv(){
      var secondDivCss='position:absolute;'
      if(this.width>this.height){
        secondDivCss+='width:'+this.width*0.4+'px;height:'+this.height+'px;'
        secondDivCss+='left:'+this.width*0.6+'px;'
      }
      else{
        secondDivCss+='width:'+this.width+'px;height:'+this.height*0.4+'px;'
        secondDivCss+='top:'+this.height*0.6+'px;'
      }
      return secondDivCss
    },
    placeLogo(){
      const logoSize = Math.round(Math.min(this.width, this.height) / 8)
      let logoCss = 'position:absolute;width:'+logoSize+'px;height:'+logoSize+'px;'
      logoCss+='top:'+logoSize+'px;'
      if(this.width>this.height){
        logoCss+='left:'+((this.width-logoSize)*0.3)+'px;'
        this.logoPlace.left = (this.width-logoSize)*0.3
        this.logoPlace.right = ((this.width-logoSize)*0.3 ) + logoSize
        this.logoPlace.top = logoSize
        this.logoPlace.bottom = logoSize+logoSize
      }
      else{
        logoCss+='left:'+((this.width-logoSize)/2)+'px;'
        this.logoPlace.left = (this.width-logoSize)/2
        this.logoPlace.right = ((this.width-logoSize)/2 ) + logoSize
        this.logoPlace.top = logoSize
        this.logoPlace.bottom = logoSize+logoSize
      }
      return logoCss
    },
    placeHeading(){
      let headingCss = 'position:absolute;width:100%;'
      headingCss+='top:'+(this.logoPlace.bottom + (this.logoPlace.bottom - this.logoPlace.top)/2)+'px;'
      return headingCss
    },
    secondDivBg(){
      var bgCss='filter:blur(20px);background-size:cover;width:100%;height:99%'
      return bgCss
    },
    qrSize(){
      const qrSize = Math.round(Math.min(this.width, this.height) / 2)
      return qrSize
    },
    qrContainer(){
      var qrCss='position:absolute;background-color:#262020;border-radius:20px;'
      qrCss+='width:' + this.qrSize()*1.1+'px;'
      qrCss+='height:' + this.qrSize()*1.1+'px;'
      if(this.width>this.height){
        qrCss+='left:'+((this.width*0.4)-this.qrSize())/2+'px;'
        qrCss+='top:' + ((this.height - this.qrSize())/2)+'px;'
      }
      else{
        qrCss+='left:'+((this.width)-this.qrSize())/2+'px;'
        qrCss+='top:' + ((this.height*0.4) - this.qrSize())/2+'px;'
      }
      return qrCss
    },
    qrCenterInContainer(){
      return 'padding:'+this.qrSize()*0.05+'px;'
    },
    makePageFullScreen(){
      var css='background-color:#262020;'
      css+='width:'+this.width+'px;'
      css+='height:'+this.height+'px;'
      css+='color:white;'
      return css
    },
  }, 
}
</script>

<style scoped>

</style>


