;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var rebootTimeout = {}
    var device = {
		keys: {}
    };
    var sourceInputs = {
		TV: 48,
        VGA: 27,
        HDMI1: 31,
        HDMI2: 32,
        HDMI3: 33   // not sure about it
	};
    var powerStates = { 
        0: 'Stand By',
        1: 'Panel On',
        2: 'Panel On',
        3: 'Panel On'
    };
    var tvKey;
    var productCode, model, guid, timeZoneOffset, powerState  // <-- remembers the last power state

    /********************/
    /* Public Functions */
    /********************/
    var Device = {
        // Initialize function
        init: function(callback) {
            var self = this, onShow, onShowCalled = false, onLoad;
                    
            // Load Samsung API JS Files from TV
            loadJS('$MANAGER_WIDGET/Common/API/TVKeyValue.js');
            loadJS('$MANAGER_WIDGET/Common/API/Widget.js');
            loadJS('$MANAGER_WIDGET/Common/API/Plugin.js');
            loadJS('$MANAGER_WIDGET/Common/webapi/1.0/webapis.js');

            // Load TV control objects
            // Generic Plugins
            this.TV         = loadObject('pluginObjectTV', 'clsid:SAMSUNG-INFOLINK-TV');
            this.NNAVI      = loadObject('pluginObjectNNavi', 'clsid:SAMSUNG-INFOLINK-NNAVI');
            this.POWEREVENT = loadObject('POWEREVENT', 'clsid:SAMSUNG-INFOLINK-SEF');
            this.SEFPLUGIN  = loadObject('SEFPLUGIN', 'clsid:SAMSUNG-INFOLINK-SEF');
            // Player Plugins
            this.TVMW       = loadObject('pluginObjectTVMW', 'clsid:SAMSUNG-INFOLINK-TVMW');
            this.SEFPLUGIN2  = loadObject('SEFPLUGIN2', 'clsid:SAMSUNG-INFOLINK-SEF');
            this.RFPLAYER   = loadObject('RFPLAYER', 'clsid:SAMSUNG-INFOLINK-SEF');
            this.IPTVPLAYER = loadObject('IPTVPLAYER', 'clsid:SAMSUNG-INFOLINK-SEF');
            this.FILEPLAYER = loadObject('FILEPLAYER', 'clsid:SAMSUNG-INFOLINK-SEF');
            // Network Plugins
            this.NIC            = loadObject('NIC', 'clsid:SAMSUNG-INFOLINK-NETWORK');
            this.NETWORK        = loadObject('NETWORK', 'clsid:SAMSUNG-INFOLINK-SEF');
            this.NETWORKEVENT   = loadObject('NETWORKEVENT', 'clsid:SAMSUNG-INFOLINK-SEF');
            // Storage Plugins
            this.DOWNLOADER     = loadObject('DOWNLOADER', 'clsid:SAMSUNG-INFOLINK-DOWNLOAD');
            
            onLoad = function() {
                if (typeof Common !== 'undefined' && Common.API && Common.API.Plugin && Common.API.Widget) {
                    tvKey = new Common.API.TVKeyValue();
                    self.widgetAPI = new Common.API.Widget();
                    self.PLUGIN = new Common.API.Plugin();
                    
                    self.SEFPLUGIN2.Open('TV', '1.000', 'TV');
                    self.SEFPLUGIN2.Execute("SetEvent", 126);
                    self.RFPLAYER.Open("Window","1.000","Window");
                    self.FILEPLAYER.Open ("Player","1.000","Player");

                    // this event should be called right after everything is ready and window is shown
                    // Or when returning to HBrowser from other source (HDMI)
                    window.onShow = onShow;
                    onShow();

                    if (navigator.userAgent.indexOf('Maple 5.1') >= 0) {
                        samsung2011();
                    }

                    self.widgetAPI.sendReadyEvent();

                } else {
                    // call this later
                    setTimeout(onLoad, 50);
                }
            };

            // 
            onShow = function() {
                // When returning from other source to HBrowser also calls onShow, do not continue
                if (onShowCalled) return;
                onShowCalled = true;


                // Start Power Events
                POWEREVENT.Open("TV","1.000","TV")
                POWEREVENT.OnEvent = PowerEventHander
                // get the curent power event
                powerState = POWEREVENT.Execute("GetPowerState")
                setTimeout(function() { EventBus.$emit('powerStateChanged', powerStates[powerState]) }, 2000)     // get initial power state
                POWEREVENT.Execute("SetEvent", 211);
                // get the widgetAPI events
                curWidget.onWidgetEvent = widgetEventHandler

                self.PLUGIN.SetBannerState(1);
                self.NNAVI.SetBannerState(1);

                model = self.TV.GetProductCode(0) || 'Unknown';
                if(typeof webapis !== 'undefined') guid = webapis.tv.info.getDeviceID() || self.NIC.GetMAC(1).toUpperCase()  // 1 is the wired card
                else guid = self.NIC.GetMAC(1).toUpperCase()
                productCode = self.NNAVI.GetModelCode() || 'Unknown';
                timeZoneOffset = self.TV.GetTimeZone_Offset();
                self.NNAVI = undefined
                self.TV = undefined

                setKeys();
                self.mute(false);

                self.PLUGIN.registAllKey();
                self.PLUGIN.unregistKey(tvKey.KEY_POWER);
                self.PLUGIN.unregistKey(tvKey.KEY_MENU);
                self.PLUGIN.unregistKey(tvKey.KEY_SOURCE);

                self.PLUGIN.setOffScreenSaver();
                
                // Stop the RETURN and EXIT defaut behavior
                window.addEventListener("keydown", function (event) {
                    switch (event.keyCode) {
                        case tvKey.KEY_RETURN:
                            self.widgetAPI.blockNavigation(event);
                            break;
                        case tvKey.KEY_EXIT:
                            self.widgetAPI.blockNavigation(event);
                            break;
                        case tvKey.KEY_HOME:
                            self.widgetAPI.blockNavigation(event);
                            break;
                    }
                });

                initDeviceStaticParams(function(result){ })
                self.serviceLock(true)
			    setDeviceCapabilities()

                if (callback) {
                    return callback(self || this);
                }
            };

            // checks if API libraries are loaded and ready, if not, its called again later
            onLoad();
        },

        // De Initialize function
        deInit: function(callback) {
        },

        serviceLock: function(state) {
			// NOT NEEDED
        },
        
        checkIn(guest) {
			return true
		},

        checkOut: function() {
			this.clearUserData()
			return true
        },
        
        persistUserData() {
			return true
		},

		clearUserData: function() {
			return true
		},

        exit: function(dvb) {
            if (this.widgetAPI) {
                if (dvb) { return this.widgetAPI.sendExitEvent(); }
                return this.widgetAPI.sendReturnEvent();
            }
        },

        openApps: function() {
            this.widgetAPI.sendReturnEvent();
        },
        
        openBrowser: function(url) {
            this.widgetAPI.runSearchWidget("29_fullbrowser", url ? url : '');
        },

		openAirTime: function() {
            this.widgetAPI.runSearchWidget(3201511006633);
        },
        
        keys: function() {
            return device.keys;
        },

		width: function() {
			return window.screen.width
        },
        
        height: function() {
			return window.screen.height
        },

        browser: function() {
            return navigator.userAgent;
        },

        firmware: function() {
            var firmware = null;
            if (this.SEFPLUGIN) {
                this.SEFPLUGIN.Close();
                this.SEFPLUGIN.Open('Device','1.000','Device');
                firmware = this.SEFPLUGIN.Execute('Firmware');
                this.SEFPLUGIN.Close();
            }
            return firmware;
        },

        vendor: function() {
			return 'Samsung';
        },
        
        year: function() {
            var nav = navigator.userAgent;
            if (nav.indexOf('SmartTV+2015; Maple2012') >= 0) {      // EE || ED
                if(this.firmware().indexOf('T-N14') === -1 && this.firmware().indexOf('T-M14') === -1) return '2015'        // ED
                return '2016';      // EE
            }
            else if (nav.indexOf('SmartTV+2014; Maple2012') >= 0) { return '2014'; }        // EC
            else if (nav.indexOf('SmartTV+2013; Maple2012') >= 0) { return '2013'; }        // EB
            else if (nav.indexOf('SmartTV; Maple2012') >= 0) { return '2012'; }             // EA
            else if (nav.indexOf('Maple 6') >= 0) { return '2011'; }
            else if (nav.indexOf('Maple 5') >= 0) { return '2010'; }
            else { return 'Unknown'; }
        },
        
        productCode: function() {
            return productCode
        },

        model: function() {
            return model
        },

        serialNumber: function() {
			return guid;
		},
        
        country: function() {
            var country = null;

            try { country = String(window.location.search.match(/country=([\w\-\_]+)/)[1]).toLowerCase(); }
            catch (e) { }

            return country;
        },
        
        language: function() {
            var language = null;

            try { language = String(window.location.search.match(/lang=([\w\-\_]+)/)[1]).toLowerCase(); } 
            catch (e) { }

            return language;
        },
        
        date: function() {
            return new Date();
        },
        
        timeZoneOffset: function() {
            return timeZoneOffset
        },

        volume: function(volume) {
            if (this.SEFPLUGIN) {
                this.SEFPLUGIN.Close();
                this.SEFPLUGIN.Open('Audio','1.000','Audio');

                if(volume === undefined) volume = this.SEFPLUGIN.Execute('GetVolume');
                else {
                    this.SEFPLUGIN.Execute("SetUserMute",0);
                    this.SEFPLUGIN.Execute("SetVolume",volume);
                    volume = this.SEFPLUGIN.Execute('GetVolume')
                    EventBus.$emit('deviceDataChanged', {'volume': volume});
                }
                this.SEFPLUGIN.Close();
                return volume
            }
            return 0
        },

        mute: function(mute) {
            if (this.SEFPLUGIN) {
                this.SEFPLUGIN.Close();
                this.SEFPLUGIN.Open('Audio','1.000','Audio');

                if(mute) this.SEFPLUGIN.Execute("SetUserMute",1);
                else this.SEFPLUGIN.Execute("SetUserMute",0);

                this.SEFPLUGIN.Close();
                return true
            }
            return false
        },
        
        remote: function(key) {
            if (this.SEFPLUGIN) {
                this.SEFPLUGIN.Close();
                this.SEFPLUGIN.Open('AppCommon','1.000','AppCommon');

                this.SEFPLUGIN.Execute("SendKeyToTVViewer", key);

                this.SEFPLUGIN.Close();
                return true
            }
            return false
        },

        updateHoteplusApp: function() {
			return true
        },
        
        updateConfiguration: function(config) {
			return true
        },
        
        // source is provided as "TV", "HDMI 1", etc
		source: function(source) {
            if(source === undefined) return "TV"
            this.SEFPLUGIN.Close();
            this.SEFPLUGIN.Open("TVMW", "1.000", "TVMW");
            this.SEFPLUGIN.Execute("SetSource", sourceInputs[source.replace(' ', '') || "TV"])
            this.SEFPLUGIN.Close();
		},

        power: function(state) {
            var result = true;
            this.SEFPLUGIN.Close();
            this.SEFPLUGIN.Open('HOTEL','1.000','HOTEL');

            if(state === undefined) result = powerStates[powerState];
            else if(state.toLowerCase() === 'on') this.SEFPLUGIN.Execute("SetPowerOn");
            else if(state.toLowerCase() === 'off') this.SEFPLUGIN.Execute("SetPowerOff");
            else if(state.toLowerCase() === 'reboot') {
                var self= this
                setTimeout(function() {self.power('on')}, 5000)
                this.power('off')
            }
            else if(state.toLowerCase() === 'reload') this.reboot()
            else result = false

            this.SEFPLUGIN.Close();

            return result
        },

        // reboot
        reboot() {
            this.SEFPLUGIN.Close();
            this.SEFPLUGIN.Open('HOTEL','1.000','HOTEL');
            this.SEFPLUGIN.Execute("SetPowerOn");       // needed because while in StandBy cannot reboot 
            this.SEFPLUGIN.Execute("SetPowerReboot");
            this.SEFPLUGIN.Close();
        }
    }

    /*********************/
    /* Private Functions */
    /*********************/

    function loadJS(src) {
        var s = document.createElement('script');
        document.head.appendChild(s);
        s.src = src;
    };

    function loadObject(id, clsid) {
        var objs = document.getElementsByTagName('object');
        if (objs) {
            for (var i in objs) {
                if (objs[i] && objs[i].id === id) {
                    return objs[i];
                }
            }
        }

        var obj = document.createElement('object');
        obj.id = id;
        obj.setAttribute('classid', clsid);

        document.body.appendChild(obj);
        return obj;
    };

    function setKeys() {
        device.keys = {
            POWER: tvKey.KEY_POWER,
            LEFT: tvKey.KEY_LEFT,
            RIGHT: tvKey.KEY_RIGHT,
            UP: tvKey.KEY_UP,
            DOWN: tvKey.KEY_DOWN,
            ENTER: tvKey.KEY_ENTER,
            RETURN: tvKey.KEY_RETURN,
            HOME: tvKey.KEY_HOME,
            ZERO: tvKey.KEY_0,
            ONE: tvKey.KEY_1,
            TWO: tvKey.KEY_2,
            THREE: tvKey.KEY_3,
            FOUR: tvKey.KEY_4,
            FIVE: tvKey.KEY_5,
            SIX: tvKey.KEY_6,
            SEVEN: tvKey.KEY_7,
            EIGHT: tvKey.KEY_8,
            NINE: tvKey.KEY_9,
            RED: tvKey.KEY_RED,
            GREEN: tvKey.KEY_GREEN,
            YELLOW: tvKey.KEY_YELLOW,
            BLUE: tvKey.KEY_BLUE,
            PLAY: tvKey.KEY_PLAY,
            PAUSE: tvKey.KEY_PAUSE,
            STOP: tvKey.KEY_STOP,
            REC: tvKey.KEY_REC,
            FF: tvKey.KEY_FF,
            RW: tvKey.KEY_RW,
            TOOLS: tvKey.KEY_TOOLS,
            PUP: tvKey.KEY_CH_UP,
            PDOWN: tvKey.KEY_CH_DOWN,
            VUP: tvKey.KEY_VOL_UP,
            VDOWN: tvKey.KEY_VOL_DOWN,
            MUTE: tvKey.KEY_MUTE,
            CHLIST: tvKey.KEY_CHLIST,
            SOURCE: tvKey.KEY_SOURCE,
            GUIDE: tvKey.KEY_GUIDE,
            PRECH: tvKey.KEY_PRECH,
            TXTMIX: tvKey.KEY_TTX_MIX,
            FAVCH: tvKey.KEY_FAVCH,
            EXIT: tvKey.KEY_EXIT,
            INFO: tvKey.KEY_INFO
        };
    };

    function PowerEventHander(event, id, data) {
        //console.error('Event: ' + event + ', id: ' + id + ', data: ' + data)
        switch(parseInt(id)) {
            case 211 :
                powerState = Device.POWEREVENT.Execute("GetPowerState")
                EventBus.$emit('powerStateChanged', powerStates[powerState]);

                // simulate "Reboot Time 1sec" when StandBy
                if(powerStates[powerState] == 'Stand By') {
                    clearTimeout(rebootTimeout)
                    if(global.vm.$root.settings.vReboot) rebootTimeout = setTimeout(Device.power('reload'), 1000)
                }
                else clearTimeout(rebootTimeout)
            break;
        }
    }

    function widgetEventHandler(event) {
        // If exited from application
        if(event.type == Common.API.EVENT_ENUM.GET_HIGHTLIGHT) {
            // enable project
            global.vm.$root.visible.project = true
            // if it was screen mirroring exit and wifi should be on, re enable it
            if(global.vm.$root.network.mirroringRequested) {
                global.vm.$root.network.mirroringRequested = false
                if(global.vm.$root.network.wifiShouldBeOn) {
                    global.vm.$root.network.wifiShouldBeOn = false
                    global.vm.$root.network.softApEnabled(true)
                }
            }
        }
    }

    function samsung2011() {
        Date._parse = Date.parse;
        Date.parse = function(str) {
            if (str.match(/^[\d\-]+\T[\d\-\+\:]+$/)) {
                var p = str.split(/\-|\+|\:|\T/);
                return (new Date(p[0], parseInt(p[1].replace(/^0/, '')) - 1, p[2], p[3], p[4], p[5])).getTime();
            }

            return Date._parse(str);
        };
    }

    function initDeviceStaticParams(callback) {
        EventBus.$emit('deviceDataChanged', {
            'volume': Device.volume(),
            'vendor': Device.vendor(),
            'browser': Device.browser(),
            'firmware': Device.firmware(),
            'model': Device.model(),
            'serial': Device.serialNumber()
        })
        callback(true)
    }
    
	function setDeviceCapabilities() {
        global.vm.$root.capabilities.css.filterBlur = false
        global.vm.$root.capabilities.css.svgBlur = true
	}

    window.Device = Device;

    if (typeof module === 'object') {
        module.exports = Device;
    }
})();