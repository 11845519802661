;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var device = {
		keys: {}, signage: {}, storage: {}, configuration: {}, deviceInfo: {}, inputSource: {}, power: {}, sound: {}, video: {}
	};
	var deviceInfo = { model: '', firmware: '', serial: '' }
	var powerState = "Panel On"
	var powerStates = { 
		'Active': 'Panel On',
        'Screen Off': 'Stand By'
	};
	
	var webOSver = "1.0"
	var apiVer = "1.2"
	var verToAPImap = { "1.0": "1.2", "2.0": "1.3", "3.0": "1.4", "3.2": "1.5", "4.0": "1.6", "4.1": "1.7", "5.0": "1.8" };
	var LGcordovaCustom = ''		// LG cordova custom.js library

    /********************/
    /* Public Functions */
	/********************/
    var Device = {
        // Initialize function
		init: function(callback) {
			this.setKeys();
			this.serviceLock(true)
			setTimeout(setDeviceCapabilities, 100)

			loadWebOsAPI(function(error, result) {
				// get current panel state
				device.power.getPowerStatus(
					function (state) { 
						powerState = powerStates[state.displayMode] 
						EventBus.$emit('powerStateChanged', powerState);
					}, 
					function (e) { }
				)

				device.deviceInfo.getPlatformInfo(
					function(info) { 
						deviceInfo.model = info.modelName
						deviceInfo.firmware = info.firmwareVersion
						deviceInfo.serial = info.serialNumber
						initDeviceStaticParams(function(result){ })
					},
					function(error) { console.error('Failed to get Platform Info: ' + error.errorText) }
				)
				
				return callback ? callback(this) : this
			}.bind(this))
		},

		serviceLock: function(state) {
			// NOT NEEDED
		},

		checkIn(guest) {
			return true
		},

		checkOut: function() {
			this.clearUserData()
			return true
		},
		
		persistUserData() {
			return true
		},

		clearUserData: function() {
			return true
		},

        keys: function() {
            return device.keys;
		},

		apps: function() {
			return [
				{name: 'Netflix', id: 0, logo: './assets/img/apps/Netflix.png'},
				{name: 'Facebook', id: 1, logo: 'https://findicons.com/files/icons/524/web_2/256/facebook.png'},
				{name: 'You Tube', id: 2, logo: './assets/img/apps/YouTube.png'},
				{name: 'PrimeVideo', id: 3, logo: './assets/img/apps/PrimeVideo.png'},
				{name: 'Linked In', id: 4, logo: 'https://findicons.com/files/icons/2377/socialclean/128/linkedin.png'},
				{name: 'Skype', id: 5, logo: 'https://findicons.com/files/icons/727/leopard/128/skype.png'},
				{name: 'Instagram', id: 6, logo: 'https://findicons.com/files/icons/2791/boxed_metal_icons/128/instagram.png'}
			]
		},

		openApps: function() {
            global.vm.$root.visible.apps = true
		},

		openBrowser: function(url) {
            return
		},

		openAirTime: function() {
            return
		},
		
		launchApp: function(appName) {
			console.warn('Launch App is not supported in Browser')
			return true
		},
		
		width: function() {
			return window.innerWidth
        },
        
        height: function() {
			return window.innerHeight 
        },
        
        browser: function() {
            return navigator.userAgent;
		},
		
		firmware: function() {
			return deviceInfo.firmware;
		},

		vendor: function() {
			return 'LG';
		},

		model: function() {
			return deviceInfo.model;
		},

		serialNumber: function() {
			return deviceInfo.serial;
		},

		productCode: function() {
			return 'Unknown';
		},

		country: function() {
			var country = null;

			try {
				country = String(window.navigator.language).toLowerCase().split('-')[1];
			} catch (e) {

			}

			return country;
		},

		language: function() {
			var language = null;

			try {
				language = String(window.navigator.language).toLowerCase();
			} catch (e) {

			}

			return language;
		},

		date: function() {
			return new Date();
		},

		timeZoneOffset: function() {
			return new Date().getTimezoneOffset();
		},

		clearHistory: function() {
			return null;
		},

		pushHistory: function() {
			return null;
		},

		exit: function(dvb) {
			return null;
		},
		
		volume: function(volume) {
			if(volume === undefined) {
				device.sound.getSoundStatus(
					function(vol) { EventBus.$emit('deviceDataChanged', {'volume': vol.level}) },
					function(error) { console.error('Get Volume failure: ' + error.errorText) }
				)
			}
			else {
				device.sound.setVolumeLevel(
					function () { EventBus.$emit('deviceDataChanged', {'volume': volume}); }, 
					function (error) { console.error('Set volume failed, ' + error.errorText ) }, 
					{ level: volume, volOsdEnabled: 0 }
				)
			}
        },

        mute: function(mute) {
            device.sound.setVolumeLevel(
				function () { EventBus.$emit('deviceDataChanged', {'volume': 0}); }, 
				function (error) { console.error('Set volume failed, ' + error.errorText ) }, 
				{ level: 0, volOsdEnabled: 0 }
			)
			return true
		},

		remote: function(key) {
			//global.vm.$root.triggerKeyboardEvent(document, key)
			console.log(key)
			if(key === 409) { // power
				console.log('POWER')
                if(powerState === 'Panel On') this.power('off')
                else this.power('on')
                return 
            }
            // In Tizen we cannot emulate keypress in API so...
            var event = document.createEventObject ? document.createEventObject() : document.createEvent("Events");
            event.keyCode = key;
            event.which = key;
            EventBus.$emit('keyDown', event)
            global.vm.$root.keyPressed(event)
		},

		updateHoteplusApp: function() {
			return true
		},

		updateConfiguration: function(config) {
			return true
		},

		// source is provided as "TV", "HDMI 1", etc
		source: function(source) {
            return false
		},
		
		power: function(state) {
			if(state === undefined) return powerStates[powerState];

			if(state.toLowerCase() === 'on') {
				device.power.setDisplayMode(
					function() { 
						powerState = 'Panel On'
						EventBus.$emit('powerStateChanged', powerState);
					},
					function(error) { console.error('POWER ON Failed ' + error.errorText) },
					{ displayMode: Power.DisplayMode.DISPLAY_ON }
				)
			}
			else if(state.toLowerCase() === 'off') {
				device.power.setDisplayMode(
					function() { 
						powerState = 'Stand By'
						EventBus.$emit('powerStateChanged', powerState);
					},
					function(error) { console.error('POWER OFF Failed ' + error.errorText) },
					{ displayMode: Power.DisplayMode.DISPLAY_OFF }
				)
			}
			else if(state.toLowerCase() === 'reboot' || state.toLowerCase() === 'reload') {
				device.power.executePowerCommand(
					function() { },
					function(error) { console.error('REBOOT Failed ' + error.errorText) },
					{ powerCommand: Power.PowerCommand.REBOOT }
				)
			}
			else return false
        },
		
		setKeys: function() {
            device.keys = {
				POWER: 409,
				RIGHT: 39,
				LEFT: 37,
				UP: 38,
				DOWN: 40,
				RETURN: 461,
				EXIT: 1001,
				ENTER: 13,
				HOME: 602,
				PLAY: 415,
				PAUSE: 19,
				STOP: 413,
				FF: 417,
				RW: 412,
				//TOOLS: ?,
				RED: 403,
				GREEN: 404,
				YELLOW: 405,
				BLUE: 406,
				ZERO: 48,
				ONE: 49,
				TWO: 50,
				THREE: 51,
				FOUR: 52,
				FIVE: 53,
				SIX: 54,
				SEVEN: 55,
				EIGHT: 56,
				NINE: 57,
				PUP: 427,
				PDOWN: 428,
				VUP: 447,
				VDOWN: 448,
				//MUTE: 0,
				//CHLIST: 0,
				//SOURCE: 0,
				GUIDE: 458,
				APPS: 93,
				INFO: 457,
				NETFLIX: 1037
			}
		}
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
	
	window.Device = Device;
	
	function initDeviceStaticParams(callback) {
		var deviceInfo = { }

		deviceInfo['state'] = powerState
		deviceInfo['vendor'] = Device.vendor()
		deviceInfo['browser'] = Device.browser()
		deviceInfo['firmware'] = Device.firmware()
		deviceInfo['model'] = Device.model()
		deviceInfo['serial'] = Device.serialNumber()
		EventBus.$emit('deviceDataChanged', deviceInfo)
		callback(true)
	}

	// Load LG WebOS and Cordova specific libraries
	function loadWebOsAPI(callback) {
		var jsURL = process.env.NODE_ENV === 'production' ? './' : 'http://192.168.10.100/tv/'
		loadJS(jsURL + 'webOS/cordova/2.7.0/cordova.webos.js', function(error, result) {
			if(error) return callback('Error loading the WebOS cordova library', false)
			loadJS(jsURL + 'webOS/custom/custom.js', function(error, result) {
				if(error) return callback('Error loading the custom cordova library', false)
				LGcordovaCustom = new Custom
				getWebOsVersion(function() {
					loadArrJS([
						jsURL + "webOS/cordova-cd/" + apiVer + "/signage.js",
						jsURL + "webOS/cordova-cd/" + apiVer + "/storage.js",
						jsURL + "webOS/cordova-cd/" + apiVer + "/configuration.js",
						jsURL + "webOS/cordova-cd/" + apiVer + "/deviceInfo.js",
						jsURL + "webOS/cordova-cd/" + apiVer + "/inputSource.js",
						jsURL + "webOS/cordova-cd/" + apiVer + "/power.js",
						jsURL + "webOS/cordova-cd/" + apiVer + "/sound.js",
						jsURL + "webOS/cordova-cd/" + apiVer + "/video.js"
					], function() {
						device.signage = new Signage
						device.storage = new Storage
						device.configuration = new Configuration
						device.deviceInfo = new DeviceInfo
						device.inputSource = new InputSource
						device.power = new Power
						device.sound = new Sound
						device.video = new Video

						console.log('Loaded WebOS cordova library')
						return callback(null, true)
					})
				})
			})
		})
	}

	function getWebOsVersion(callback) {
		LGcordovaCustom.Signage.getwebOSVersion((function (version) {
			console.log("webOS Signage version: " + version.webOSVersion)
			webOSver = version.webOSVersion.toString()
			verToAPImap.hasOwnProperty(webOSver) ? apiVer = verToAPImap[webOSver] : (console.error("WebOS API not compatible " + webOSver), apiVer = "1.4")
			callback()
		}), (function (error) {
			console.error("[" + error.errorCode + "]" + error.errorText),
			webOSver = "6.0+",
			apiVer = "1.4"
			callback()
		}))
	}

	function loadJS (src, callback) {
		var scrElem = document.createElement("script");
		scrElem.type = "text/javascript",
		scrElem.src = src,
		scrElem.onload = function () {
			callback ? callback(null, true) : ''
		},
		scrElem.onerror = function (e) {
			callback ? callback('ERROR', false) : ''
		},
		document.head.appendChild(scrElem);
	}

	function loadArrJS (srcArr, callback) {
		var callbackCount = 0
		var srcEls = []
		for(let i = 0; i < srcArr.length; i++) {
			srcEls[i] = document.createElement("script");
			srcEls[i].type = "text/javascript",
			srcEls[i].src = srcArr[i],
			srcEls[i].onload = function () {
				if(++callbackCount == srcArr.length) return callback()
			},
			srcEls[i].onerror = function (e) {
				console.error('Failed to load WebOS module ' + srcArr[i])
				if(++callbackCount == srcArr.length) return callback()
			},
			document.head.appendChild(srcEls[i]);
		}
	}

	function setDeviceCapabilities() {
		global.vm.$root.capabilities.css.filterBlur = true
		global.vm.$root.capabilities.css.svgBlur = true
	}

    if (typeof module === 'object') {
        module.exports = Device;
    }
})();