;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var device = {
		keys: {}
	};
	var sourceInputs = {
		TV: 1,
		HDMI: 6
	};
	var sourceIndexes = {
		1: {0: 'TV'},
		6: {0: 'HDMI 1', 1: 'HDMI 2', 2: 'HDMI 3'}
	}
	var powerStates = { 
		1: 'Panel On',
        2: 'Stand By'
	};
	var apps = []  // we want it in the form {name: 'Facebook', id: 1, logo: 'https://findicons.com/files/icons/524/web_2/256/facebook.png'},
	var powerState = 1

    /********************/
    /* Public Functions */
	/********************/
    var Device = {
		// Initialize function
		init: function(callback) {
			this.setKeys();
			setInitialConfig();
			initDeviceStaticParams(function(result){ })
			this.serviceLock(true)
			setTimeout(setDeviceCapabilities, 100)

			// get the curent power event
			hcap.power.getPowerMode({ 
				"onSuccess":function(s) { 
					powerState = s.mode;
					EventBus.$emit('powerStateChanged', powerStates[powerState]);
				},
				"onFailure":function(f) { console.error("getPowerMode failed " + f.errorMessage); }
			});

			// start listeners
			var self = this
			document.addEventListener("hcap_application_focus_changed",
				function (param) {
					// Hoteplus is in background now, stop audio/video because another App opened (Netflix freezes if video in page playing)
					if(param.eventType == 'unfocused') {
						EventBus.$emit('globalStopVideosAndAudios');
					}
					// Hoteplus is in foreground now
					else if(param.eventType == 'focused') {
						EventBus.$emit('globalStartVideosAndAudios');
					}
				},
				false
			);
			// in manual change source, if we do not do the below, the project will hide the source
			document.addEventListener("external_input_changed", function() {
				hcap.externalinput.getCurrentExternalInput({
					"onSuccess": function (s) {
						console.log("Source changed to " + sourceIndexes[s.type][s.index]);
						if(sourceIndexes[s.type][s.index] === 'TV') { setTimeout(function() {stopRogueChannelPlaying()}, 2000) }	// when returning to TV input it plays channel behind project
						global.vm.$root.changeSource(sourceIndexes[s.type][s.index])
					}
				});
			})
			document.addEventListener("volume_level_changed", function() { self.volume() })
			document.addEventListener("power_mode_changed", function() { 
				hcap.power.getPowerMode({ 
					"onSuccess":function(s) { 
						powerState = s.mode;
						EventBus.$emit('powerStateChanged', powerStates[s.mode]);
					},
					"onFailure":function(f) { console.error("getPowerMode failed " + f.errorMessage); }
				});
			})
			document.addEventListener("channel_changed", stopRogueChannelPlaying)
			// If network reachable, re-calculate applications list
			document.addEventListener("network_event_received", function(r) {
				if (r.event === 14) {
					setTimeout(function() {
						// Make a list with applications
						getApps(function(error, appsList) {
							if(error) console.log('Failed to retrieve Apps list')
							else apps = appsList
						})
					}, 5000)
				}
			 })

			// Make a list with applications, give some time to ensure internet access, cause YouTube and Browser will be missing
			setTimeout(function() {
				getApps(function(error, appsList) {
					if(error) console.log('Failed to retrieve Apps list')
					else apps = appsList
				})
			}, 5000)

			typeof callback === 'function' && callback(this)
			return this;
		},

		serviceLock: function(state) { 
			// NOT NEEDED
		},

		checkIn(guest) {
			return true
		},

		checkOut() {
			this.clearUserData()
			hcap.checkout.requestCheckout({
				"onSuccess":function() { console.warn("CheckOut: Cleared personal data") }, 
				"onFailure":function(f) { console.error('Failed to checkOut, ' + f.errorMessage)}
			})
			if(global.vm.$root.settings.autoSapPass) global.vm.$root.network.softApPass(randomWiFiPass())
			return true
		},

		persistUserData() {
			hcap.property.setProperty({ "key" : "security_level", "value" : "2", "onSuccess" : function() { }, "onFailure" : function(f) { } });
			return true
		},

		clearUserData() {
			hcap.property.setProperty({ "key" : "security_level", "value" : "1", "onSuccess" : function() { }, "onFailure" : function(f) { } });
			return true
		},

        keys: function() {
            return device.keys;
		},

		apps: function() {
			return apps
		},

		openApps: function() {
            global.vm.$root.visible.apps = true
		},
		
		openBrowser: function(url = 'https://www.google.com') {
			var self = this
			console.log('browser')
            hcap.property.setProperty({ 
				"key":"full_browser_start_page_url", 
				"value":url, 
				"onSuccess":function() { self.launchApp("Web Browser") }, 
				"onFailure":function(f) { console.error('Failed to open browser')}
			});
		},

		openAirTime: function() {
			hcap.preloadedApplication.launchPreloadedApplication({
				'id':'201711221538035001',
				'onSuccess':function() {console.log('Started Airtime Application')},
				'onFailure':function(f) { console.error('Start Airtime app failed: ' + f.errorMessage) }
			})
		},
		
		launchApp: function(appName) {
			if(appName == 'Netflix') return Network.openNetflix('launcher')			// because we need custom parameters call and statistics
			hcap.preloadedApplication.launchPreloadedApplication({
				'id':getAppByName(appName).id.toString(),
				'onSuccess':function() {},
				'onFailure':function(f) { console.error('Failed to launch app: ' + appName + ' ' + f.errorMessage) }
			})
		},
		
		width: function() {
			return window.innerWidth
        },
        
        height: function() {
			return window.innerHeight 
        },
        
        browser: function() {
            return navigator.userAgent;
		},
		
		firmware: function(callback) {
			getProperty('platform_version', function(firmware) {
				callback(firmware) 
			})
		},

		vendor: function() {
			return 'LG';
		},

		serialNumber: function(callback) {
			getProperty('serial_number', function(serial) {
				callback(serial) 
			})
		},

		model: function(callback) {
			getProperty('model_name', function(model) {
				callback(model) 
			})
		},

		productCode: function() {
			return device.productCode;
		},

		country: function() {
			var country = null;

            try { country = String(window.location.search.match(/country=([\w\-\_]+)/)[1]).toLowerCase(); }
            catch (e) { }

            return country;
		},

		language: function() {
			var language = null;

            try { language = String(window.location.search.match(/lang=([\w\-\_]+)/)[1]).toLowerCase(); } 
            catch (e) { }

            return language;
		},

		date: function() {
			return new Date();
		},

		timeZoneOffset: function() {
			return new Date().getTimezoneOffset();
		},

		clearHistory: function() {
			return null;
		},

		pushHistory: function() {
			return null;
		},

		exit: function(dvb) {
			return null;
		},
		
		volume: function(volume) {
			if(volume === undefined) {
				hcap.volume.getVolumeLevel({
					'onSuccess': function(s) { EventBus.$emit('deviceDataChanged', {'volume': s.level}); },
					'onFailure': function(f) { console.error('GET Volume failure: ' + f.errorMessage) }
				})
			}
			else {
				hcap.volume.setVolumeLevel({
					"level": volume,
					"onSuccess":function() { },
					"onFailure":function(f) { console.error("Volume change failure = " + f.errorMessage) }
				})
			}
        },

        mute: function(mute) {
			hcap.volume.setVolumeLevel({
				"level": 0,
				"onSuccess":function() { },
				"onFailure":function(f) { console.error("TV: mute failure = " + f.errorMessage) }
			})
            return true
		},

		remote: function(key) {
            hcap.key.sendKey({
				"virtualKeycode" : key,
				"onSuccess" : function() { },
				"onFailure" : function(f) { console.error("TV: Remote Command failed: " + f.errorMessage) }
			})
			return true
		},

		updateHoteplusApp: function() {
			hcap.power.powerOff({
				"onSuccess" : function(r) {}, 
				"onFailure" : function(f) { console.error('Update Failed ' + f.errorMessage) }
			})
		},

		updateConfiguration: function(config) {
			hcap.system.requestCloning({ 
				"xml": config.config,
				"onSuccess":function(s) {
					console.warn('New configuration deployed!'); 
					EventBus.$emit('deviceDataChanged', {'config': config.name});
				},
				"onFailure":function(f) { console.warn("Configuration deployment failed"); }
			});
		},

		// source is provided as "TV", "HDMI 1", etc
		source: function(source) {
			if(source === undefined) return "TV"
			hcap.externalinput.setCurrentExternalInput({ 
				"type": sourceInputs[source.split(' ')[0] || "TV"],
				"index": source.split(' ')[1] - 1 || 0,
				"onSuccess":function() { console.log('Source input changed to ' + source) }, 
				"onFailure":function(f) { console.error('Failed to change source input: ' + f.errorMessage )}
			});
		},
		
		power: function(state) {
			if(state === undefined) return powerStates[powerState];

			if(state.toLowerCase() === 'on') {
				hcap.power.getPowerMode({ 
					"onSuccess":function(s) {
						if(s.mode !== 1) {
							hcap.key.sendKey({ "virtualKeycode" : hcap.key.Code.POWER, "onSuccess" : function() { }, "onFailure" : function(f) { console.error('POWER ON Failed ' + f.errorMessage) } })
						}
					},
					"onFailure":function(f) { console.error("getPowerMode failed " + f.errorMessage); }
				})
			}
			else if(state.toLowerCase() === 'off') {
				hcap.power.getPowerMode({ 
					"onSuccess":function(s) {
						if(s.mode === 1) {
							hcap.key.sendKey({ "virtualKeycode" : hcap.key.Code.POWER, "onSuccess" : function() { }, "onFailure" : function(f) { console.error('POWER OFF Failed ' + f.errorMessage) } })
						}
					},
					"onFailure":function(f) { console.error("getPowerMode failed " + f.errorMessage); }
				})
			}
			else if(state.toLowerCase() === 'reboot') {
				var self= this
				setTimeout(function() {self.power('on')}, 5000)
				this.power('off')
			}
			else if(state.toLowerCase() === 'reload') hcap.power.reboot({ "onSuccess" : function() { }, "onFailure" : function(f) { console.error('REBOOT Failed ' + f.errorMessage) } })
			else return false

			return true
		},
		
		standByOn: function() {
			hcap.power.setPowerMode({
				"mode":hcap.power.PowerMode.WARM,
				"onSuccess":function() { },
				"onFailure":function(f) { console.error("Could not enable Virtual StandBy" + f.errorMessage); }
			});
        },
		
		setKeys: function() {
            device.keys = {
				POWER: 409,
				RIGHT: 39,
				LEFT: 37,
				UP: 38,
				DOWN: 40,
				RETURN: 461,
				EXIT: 1001,
				ENTER: 13,
				HOME: 602,
				PLAY: 415,
				PAUSE: 19,
				STOP: 413,
				FF: 417,
				RW: 412,
				//TOOLS: ?,
				RED: 403,
				GREEN: 404,
				YELLOW: 405,
				BLUE: 406,
				ZERO: 48,
				ONE: 49,
				TWO: 50,
				THREE: 51,
				FOUR: 52,
				FIVE: 53,
				SIX: 54,
				SEVEN: 55,
				EIGHT: 56,
				NINE: 57,
				PUP: 427,
				PDOWN: 428,
				VUP: 447,
				VDOWN: 448,
				//MUTE: 0,
				//CHLIST: 0,
				//SOURCE: 0,
				GUIDE: 458,
				APPS: 93,
				INFO: 457,
				NETFLIX: 1037
			}
		}
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
	function getProperty(type, callback) {
		hcap.property.getProperty({
			"key" : type,
			"onSuccess" : function(s) { callback(s.value); },
			"onFailure" : function(f) {
				console.log("ERROR: get TV property" + f.errorMessage);
				callback('Unknown')
			}
		})
	}

	function initDeviceStaticParams(callback) {
		var deviceInfo = { }

		Device.volume()
		deviceInfo['vendor'] = 'LG'
		deviceInfo['browser'] = Device.browser()
		Device.firmware(function(firmware) {
			deviceInfo['firmware'] = firmware
			Device.model(function(model) {
				deviceInfo['model'] = model
				Device.serialNumber(function(serial) {
					deviceInfo['serial'] = serial
					deviceInfo['state'] = powerStates[powerState]
					EventBus.$emit('deviceDataChanged', deviceInfo)
					callback(true)
				})
			})
		})
	}

	function getApps(callback) {
		var appsList = []
		hcap.preloadedApplication.getPreloadedApplicationList({
			"onSuccess":function(s) { 
				for(var i = 0; i < s.list.length; i++) {
					//console.log(s.list[i].title.toLowerCase() + ' : ' + s.list[i].id)
					// exclude screen mirroring
					var title = s.list[i].title.toLowerCase()
					if(title.indexOf('mira') !== -1 || title.indexOf('share') !== -1 || title.indexOf('mirror') !== -1 || title.indexOf('guide') !== -1 || title.indexOf('-browser') !== -1) continue
					appsList.push({
						name: s.list[i].title,
						id: s.list[i].id,
						logo: s.list[i].iconFilePath
					})
				}
				// check if netflix present and move it to first position
				for(let j = 0; j < appsList.length; j++) {
					if(appsList[j].name !== 'Netflix') continue
					let k = appsList.splice(j, 1)
					appsList.unshift(k[0])
					break
				}

				return callback(null, appsList)
			},
			"onFailure":function(f) { return callback("Failed to getPreloadedApplicationList: " + f.errorMessage, false);}
		});
	}

	function setInitialConfig() {
		// Check if it is configured or not
		getProperty('tv_channel_attribute_floating_ui', function(value) {
			if(window.localStorage.getItem('initialConfiged') == 3 || value != 1) { return console.log('LG initial configuration skipped') }
			window.localStorage.setItem('initialConfiged', 3);

			// Stop Screen Saver
			// hcap.property.setProperty({ "key":"screensaver_control", "value":"1" });
			// Stop no Signal Logo
			hcap.property.setProperty({ "key":"tv_channel_attribute_floating_ui", "value":"0" });
			// Get privileges over SoftAP
			hcap.property.setProperty({ "key":"soft_ap_ui", "value":"1" });
			// Enable InstanOn Reboot
			hcap.property.setProperty({ "key":"instant_power", "value":"1" });
			// When loading app not playing channel
			hcap.property.setProperty({ "key":"boot_sequence_option", "value":"1" });
			// Let error messages to reach the application
			hcap.property.setProperty({ "key":"browser_network_error_handling", "value":"0" });
			// Change Display resolution (Not changing in browser and channels display smaller)
			// LW641H has a problem in FHD where channel does not fill the screen
			getProperty('model_name', function(model) {
				if(model.indexOf('LW641H') !== 1) hcap.property.setProperty({ "key":"display_resolution", "value":"1280x720" });
				else hcap.property.setProperty({ "key":"display_resolution", "value":"1920x1080" });
			})
			
			// Set Hotel Mode ON with Limited Mode
			var hotelMode = {
				'enable': 'on',
				'settings': { 
					'powerOnStatus': 'stand_by',
					'volume': { 'enable': 'on', 'settings': { 'startVolume': { 'enable': 'on', 'level': 10 }, 'minimumVolume': 0, 'maximumVolume': 100 } },
					'keyManagement': { 'enable': 'on', 'settings': { 'irOperation': 'normal', 'localKeyOperation': 'normal' } },
				'limitedMode': { 'enable': 'on',
					'settings': { 'setupMenu': 'on', 'programmeChange': 'on', 'menuDisplay': 'off', 'osdDisplay': 'on', 'systemProviderMode': 'off' } },
				'dtvProgrammeUpdate': 'Manual',
				'powerOnDefault': { 'enable': 'off',
					'settings': { 'input': 'off', 'programme': '2', 'avSetting': 'off', 'aspectRatio': 'disable' } },
				'auxSourceSetting': { 'enable': 'off',
					'settings': [ { 'av1': 'on' }, { 'av2': 'on' }, { 'av3': 'on' }, { 'rgb': 'on' }, { 'comp1': 'on' }, { 'comp2': 'on' }, { 'comp3': 'on' }, { 'hdmi1': 'on' }, { 'hdmi2': 'on' }, { 'hdmi3': 'on' }, { 'hdmi4': 'on' } ] },
				'powerManagement': 'off',
				'radioVideoBlank': { 'enable': 'off',
					'settings': { 'startOfRadioProgramme': 1, 'countOfRadioProgramme': 1 } } }
			}
			hcap.property.setHotelMode({ "hotelMode" : JSON.stringify(hotelMode) });

			return console.warn('LG initial configuration done')
		})
	}

	function stopRogueChannelPlaying() {
		// HACK: (occures when exiting an application), stop channel if visible.channels = false
		if(!global.vm.$root.visible.channels && !global.vm.$root.visible.guide) {
			hcap.channel.getCurrentChannel({
				"onSuccess":function(r) {
					if(r.channelStatus === 33) return
					hcap.channel.stopCurrentChannel({
						"onSuccess":function() { },
						"onFailure":function(f) { console.error("Failed to stop channel: " + f.errorMessage) }
					})
				},
				"onFailure":function(f) { console.error('Unable to get current channel: ' + f.errorMessage) }
			})
		}
	}

	function randomWiFiPass() {
        var rand = String(Math.floor(Math.random() * 100000000))
        while(rand.length < 8) rand = '0' + rand
        return rand
    }

	function getAppByName(name) {
		for(var i = 0; i < apps.length; i++) {
			if(apps[i].name == name) return apps[i]
		}
		return 'App not found'
	}

	function setDeviceCapabilities() {
		// Get TVs hcap version
		hcap.property.getProperty ({
			"key" : "hcap_middleware_version",
			"onSuccess" : function(s) { 
				var tvHcap = s.value.substring(0,4) 
				if(tvHcap < "1.24") {
					global.vm.$root.capabilities.css.filterBlur = false
					global.vm.$root.capabilities.css.svgBlur = false
				}
				else {
					global.vm.$root.capabilities.css.filterBlur = true
					global.vm.$root.capabilities.css.svgBlur = true
				}
			}
		})
		
	}

    window.Device = Device;

    if (typeof module === 'object') {
        module.exports = Device;
    }
})();