;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var network = {        
    };
    
    /********************/
    /* Public Functions */
	/********************/
    var Network = {
        // Initialize function
		init: function(callback) {         
            var self = this
            initNetworkParams(function(error, net) {
                if(error) console.error('Could not initialize network ' + error)
                callback(null, self)
            })
        },

        openNetflix(reason) {
            console.error('Netflix is not available in this device')
            return global.vm.$root.$notify("Netflix is not available in this device")
        },

        openBluetoothPlayer: function() {
            android.showToast('Bluetooth Player is not available')
            return console.warn('Bluetooth Player is not available')
        },

        openScreenMirroring: function() {
            android.launchApp('Googlecast')
            return true
            //return console.warn('Screen Mirroring is not available')
        },

        activeNic: function() {
            return android.getActiveNic();
        },
        
        // Get/Set the IP DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        ipDhcpEnabled: function(mode = '-1') {
            if(android.getIsDhcpEnabled()=="DHCP"){
                return true
            }
            return false
        },

        // Get/Set the DNS DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        dnsDhcpEnabled: function(mode = '-1') {
            return 'Unknown';
        },

        mediaConnected: function(nic) {
            return android.physicalConnection();
        },

        gatewayReachable: function(nic) {
            return android.gatewayReachable();
        },
        
        dnsReachable: function(nic) {
            return 'Unknown';
        },
        
        hasInternet: function(nic) {
            return android.hasInternet();
        },
        
        MAC: function() {
            return android.getMAC();
        },

        MACw: function() {
            return android.getMAC();
        },

        IP: function(nic) {
            return android.getIP(nic);
        },

        GW: function(nic) {
            //console.log('Gateway '+android.getGateway())
            return android.getGateway();
        },

        SM: function(nic) {
            //console.log('Subnet mask '+android.getSubnetMask())
            return android.getSubnetMask();
        },

        DNS: function(nic) {
            //console.log('DNS '+android.getDns())
            return android.getDns();
        },

        // SoftAp Functions
        softApEnabled(enable = '-1'){
            //console.log(enable)
			if(enable == true || enable == false ) {
				if(android.enableSoftAp(enable)) {
					EventBus.$emit('deviceDataChanged', { 
						'apStatus': enable,
						'apSsid': this.SSID(),
						'apPass': this.softApPass(),
					})
					return true;
				}
				else return false;
			} 
			// GET
			else {
				/*EventBus.$emit('deviceDataChanged', { 
                        'apStatus': android.softApEnabled(),
						'apSsid': this.SSID(),
						'apPass': this.softApPass(),
					})*/
				return android.softApEnabled();
			}
        },
        
        SSID(name = null){     
			if(name !== null) {
				if(android.setSSID(name)) {
					EventBus.$emit('deviceDataChanged', { 'apSsid': name })
					return true
				}
				else return false
			}
			// GET
			else {
				//console.log('hotspot ssid '+android.getSSID())
				return android.getSSID();
			}
        },

        // Get the Soft AP password
        softApPass(pass = null) {
			if(pass!==null){
				console.log('setting softap pass to '+pass)
				if(android.setPassword(pass)){
					EventBus.$emit('deviceDataChanged', { 'apPass': pass })
				}
			}
			else{
				//console.log('getting pass: '+android.getSoftApPass())
				return android.getSoftApPass();
			}
        },

        // Enable or disable the auto regeneration of password at reboot
        softApAutoResetPass(enable = -1) {
            return false
        },

        softApStrength(strength = 0) {
            return 'N/A'
        },

        softApChannel(ch = 0) {
            return 'N/A'
        }
    }
        
    /*********************/
    /* Private Functions */
    /*********************/

    function initNetworkParams(callback) {
        EventBus.$emit('deviceDataChanged', {
            'mac': Network.MAC(),
            'macw': Network.MACw(),
            'nicsCount': '',
            'ip': Network.IP(),
            'sm': Network.SM(),
            'gw': Network.GW(),
            'dns1': Network.DNS(),
            'dns2': '',
            'local': Network.gatewayReachable(),
            'internet': Network.hasInternet(),
            'dhcp': Network.ipDhcpEnabled(),
            'mode': Network.activeNic(),
            'physicalCon': Network.mediaConnected(),
            'apStatus': Network.softApEnabled(),
            'apSsid': Network.SSID(),
            'apPass': Network.softApPass(),
            'apStrength': Network.softApStrength(),
            'apChannel': Network.softApChannel()
        })
        callback(null, true)
    }

    window.Network = Network;

    if (typeof module === 'object') {
        module.exports = Network;
    }
})();