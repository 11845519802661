(function($) {
  'use strict'

  // Global Configuration 
  var storage = {}
  var production = process.env.NODE_ENV === 'production'

  // Public Functions 
  var Storage = {
		init: function(callback) {
      console.log('initialize storage')
			if (callback) {
				return callback(this)
      }
    },

    set: function(name, value) {
      if (window.localStorage) {
        return window.localStorage.setItem(name, JSON.stringify(value))
      }
      return false
    },

    get: function(name) {
      var value;
      if (window.localStorage) {
        value = window.localStorage.getItem(name)
        if(typeof value !== 'undefined'){
          try{
              return JSON.parse(value)
          }
          catch(e){
              return value
          }
        }
      }
      return false
    },

    save: function(id, size, name){
      return true
    },

    delete: function(id, size, name){
      return true
    },
    
    used: function(){
      return 3000
    },

    total: function(){
      return 4000000
    },

    clear: function(){
      if(window.localStorage){
        return window.localStorage.clear()
      }
      return false
    },
  }
       
  /* offlineMode() { return !!!window.location.hostname },
  serverHost() { return window.location.hostname || this.get('serverHost') },
  serverPort() { return !production ? 80 : window.location.port || this.get('serverPort') || 80 },
  serverProtocol() { return window.location.protocol !== 'file:' ? window.location.protocol || this.get('serverProtocol') : this.get('serverProtocol') },
  serverURL() { return !production ? this.serverProtocol() + '//192.168.10.100' : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() },
  mobilePath() { return !production ? this.serverProtocol() + '//' + this.serverHost() + ':8082' : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() + '/mobile' },
  assetsPath() { return this.serverURL() + '/contents/' },
  proxyPath() { return this.serverProtocol() + '//' + this.serverHost() + ':1' + this.serverPort() + '/fetch/' },*/

  window.ScreenStorage = Storage

  if (typeof module === 'object') {
    module.exports = ScreenStorage
  }
})()