;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var network = { }

    /********************/
    /* Public Functions */
	/********************/
    var Network = {
        // Initialize function
		init: function(callback) {
            var self = this

            // Add network state change listener
            webapis.network.addNetworkStateChangeListener(onNetChange)

            initNetworkParams(function(error, net) {
                if(error) console.error('Could not initialize network ' + error)
                callback(null, self)
            })
        },

        openNetflix(reason) {
            console.error('Netflix is not available in this device')
            return global.vm.$root.$notify("Netflix is not available in this device")
        },

        openBluetoothPlayer: function() {
            global.vm.$root.device.launchApp("bluetooth")
        },

        openScreenMirroring: function() {
            global.vm.$root.device.launchApp("screenMirroring")
        },

        // {"DISCONNECTED":0,"WIFI":1,"CELLULAR":2,"ETHERNET":3}
        activeNic: function() {
            var nic = webapis.network.getActiveConnectionType()
            if(nic == webapis.network.NetworkActiveConnectionType.WIFI) return 2
            if(nic == webapis.network.NetworkActiveConnectionType.ETHERNET) return 1
            return -1
        },

        // Get/Set the IP DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        ipDhcpEnabled: function(mode = '-1') {
            try {
                var netState = webapis.network.getIpMode()
                if(netState === webapis.network.NetworkIpMode.STATIC || netState === webapis.network.NetworkIpMode.FIXED) return false
                return true
            }
            catch(e) { return true }
        },

        // Get/Set the DNS DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        dnsDhcpEnabled: function(mode = '-1') {
            return this.ipDhcpEnabled()
        },

        mediaConnected: function() {
            if(webapis.network.getActiveConnectionType() == webapis.network.NetworkActiveConnectionType.DISCONNECTED) return false
            return true
        },

        gatewayReachable: function() {
            return webapis.network.isConnectedToGateway()
        },
        
        dnsReachable: function() {
            return webapis.network.isConnectedToGateway()
        },
        
        hasInternet: function() {
            return webapis.network.isConnectedToGateway()
        },
        
        // Returns Ethernet MAC
        MAC: function() {
            return b2bapis.b2bcontrol.getMACAddress('WIRED').replace(/:/g, '').toUpperCase();
        },

        // Returns Wireless MAC
        MACw: function() {
            return b2bapis.b2bcontrol.getMACAddress('WIRELESS').replace(/:/g, '').toUpperCase();
        },

        IP: function() {
            try { return b2bapis.b2bcontrol.getIPAddress() }
            catch(e) { return '0.0.0.0' }
        },

        GW: function() {
            try { return b2bapis.b2bcontrol.getGatewayAddress() }
            catch(e) { return '0.0.0.0' }
        },

        SM: function() {
            try { return b2bapis.b2bcontrol.getSubNetMask() }
            catch(e) { return '0.0.0.0' }
        },

        DNS: function() {
            try { return b2bapis.b2bcontrol.getDNSServer() }
            catch(e) { return '0.0.0.0' }
        },

        // SoftAp Functions
        //
        // Get/Set the Soft AP state
        softApEnabled(enable = '-1') {            
            // SET
            if(enable == true || enable == false ){
                if(enable) {
                    try { 
                        webapis.network.enableSoftAP() 
                        EventBus.$emit('deviceDataChanged', { 'apStatus': true, 'apSsid': this.SSID(), 'apPass': this.softApPass() })
                    } 
                    catch(e) {
                        console.error('Failed to enable SoftAP, ' + e.message)
                        return false
                    }
                }
                else {
                    try { 
                        webapis.network.disableSoftAP()
                        EventBus.$emit('deviceDataChanged', { 'apStatus': false, 'apSsid': this.SSID(), 'apPass': this.softApPass() }) 
                    } 
                    catch(e) {
                        console.error('Failed to disable SoftAP, ' + e.message)
                        return false
                    }
                }
            } 
            // GET
            else {
                /*setTimeout(function() {
                    //console.log(Object.getOwnPropertyNames(tizen))
                    console.log(webapis.productinfo.getModel())
                }, 5000)*/
                try { return webapis.network.isSoftAPEnabled() }
                catch (e) { 
                    console.error('Failed to GET SoftAP Status, ' + e.message)
                    return false
                }
            }
        },

        // Get/Set the SSID name
        // returns SSID on Get / true or false on Set
        SSID(name = null) {
            // SET
            if(name !== null){
                try { 
                    webapis.network.setTVName(name)
                    EventBus.$emit('deviceDataChanged', { 'apSsid': name })
                    return true
                }
                catch (e) { 
                    console.error('Failed to SET SoftAP SSID, ' + e.message)
                    return false
                }
            } 
            // GET
            else {
                try { return webapis.network.getSoftAPSSID() }
                catch (e) { 
                    console.error('Failed to GET SoftAP SSID, ' + e.message)
                    return false
                }
            }
        },

        // Get the Soft AP password
        softApPass() {
            try { return webapis.network.getSoftAPSecurityKey() }
            catch (e) { 
                console.error('Failed to GET SoftAP Pass, ' + e.message)
                return false
            }
        },

        // Enable or disable the auto regeneration of password at reboot
        softApAutoResetPass(enable = -1) {
            try { 
                webapis.network.SetSoftAPSecurityKeyAutoGeneration(enable)
                return true
            }
            catch (e) { 
                console.error('Failed to SET SoftAP key auto generation, ' + e.message)
                return false
            }
        },

        // Get the Soft AP Strength (returns 1-5)
        // Set (input 1-5, returns true/false)
        softApStrength(strength = 0) {
            var result = ''

            // SET
            if(strength === 1 || strength === 2 || strength === 3 || strength === 4 || strength === 5) {
                try { 
                    webapis.network.setSoftAPSignalStrength(strength * 20)
                    Storage.set('softApStrength', strength);
                    EventBus.$emit('deviceDataChanged', { 'apStrength': strength });
                    result = true
                }
                catch (e) { 
                    console.error('Failed to SET SoftAP Strength, ' + e.message)
                    result = false
                }
            }
            // GET
            else {
                result = Storage.get('softApStrength');
                if(!result) result = 5;
            }

            return result
        },
        
        // Get the Soft AP Channel (returns number)
        // Set (input 1-12, returns true/false)
        softApChannel(ch = 0) {
            var result = ''

            // SET
            // find something better here, maybe Number.isInteger with polyfill and then (ch >= 1 && ch <= 12) 
            if(ch === 1 || ch === 2 || ch === 3 || ch === 4 || ch === 5 || ch === 6 || ch === 7 || ch === 8 || ch === 9 || ch === 10 || ch === 11 || ch === 12) {
                try { 
                    webapis.network.setSoftAPChannel(ch)
                    Storage.set('softApChannel', ch);
                    EventBus.$emit('deviceDataChanged', { 'apChannel': ch });
                    result = true
                }
                catch (e) { 
                    console.error('Failed to SET SoftAP Channel, ' + e.message)
                    result = false
                }
            }
            // GET
            else {
                result = Storage.get('softApChannel');
                if(!result) result = 1;
            }

            return result
        }
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
    function initNetworkParams(callback) {
        var ip = Network.IP()
        if(ip == '0.0.0.0') setTimeout(function() { EventBus.$emit('deviceDataChanged', {'ip': Network.IP()}) }, 30000)     // solve ip 0.0.0.0 issue
        EventBus.$emit('deviceDataChanged', {
            'mac': Network.MAC(),
            'macw': Network.MACw(),
            'nicsCount': '',
            'ip': ip,
            'sm': Network.SM(),
            'gw': Network.GW(),
            'dns1': Network.DNS(),
            'dns2': '',
            'local': Network.gatewayReachable(),
            'internet': Network.hasInternet(),
            'dhcp': Network.ipDhcpEnabled(),
            'mode': Network.activeNic(),
            'physicalCon': Network.mediaConnected(),
            'apStatus': Network.softApEnabled(),
            'apSsid': Network.SSID(),
            'apPass': Network.softApPass(),
            'apStrength': Network.softApStrength(),
            'apChannel': Network.softApChannel()
        })
        //AU800 when rebooted reports disabled SoftAP even if enabled and takes some time to report the correct status
        setTimeout(function() {
            EventBus.$emit('deviceDataChanged', { 'apStatus': Network.softApEnabled() })
        }, 2000)
        
        callback(null, true)
    }

    function onNetChange(data) {
        initNetworkParams(function(error, result) {})
    }

    window.Network = Network;

    if (typeof module === 'object') {
        module.exports = Network;
    }
})();