;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var network = {        
    };
    
    /********************/
    /* Public Functions */
	/********************/
    var Network = {
        // Initialize function
		init: function(callback) {
      var self = this
      initNetworkParams(function(error, net) {
          if(error) console.error('Could not initialize network ' + error)
          callback(null, self)
      })
    },


    openScreenMirroring: function() {
        android.showToast('Screen Mirroring is not available in this device')
        return console.warn('Screen Mirroring is not available')
    },

    activeNic: function() {
        return android.getActiveNic();
    },
        
    dhcp: function(mode = '-1') {
      return 'Unknown';
    },

    dnsDhcpEnabled: function(mode = '-1') {
      return 'Unknown';
    },

    mediaConnected: function(nic) {
        return 'Unknown';
    },

    gatewayReachable: function(nic) {
        return 'Unknown';
    },
    
    dnsReachable: function(nic) {
        return 'Unknown';
    },
    
    hasInternet: function(nic) {
        return 'Unknown';
    },
    
    mac: function() {
        return android.getMAC();
    },

    macWireless: function() {
        return android.getMAC();
    },

    ip: function(nic) {
        return android.getIP(nic);
    },

    gateway: function(nic) {
        return '0.0.0.0';
    },

    subnet: function(nic) {
        return '0.0.0.0';
    },

    dns: function(nic) {
        return '0.0.0.0';
    },

   
  }
        
  // Private Functions 

  function initNetworkParams(callback) {
      EventBus.$emit('deviceDataChanged', {
          'mac': Network.mac(),
          'macWireless': Network.macWireless(),
          'ip': Network.ip(),
          'subnet': Network.subnet(),
          'gateway': Network.gateway(),
          'dns1': Network.dns(),
          'dns2': '',
          'local': Network.gatewayReachable(),
          'internet': Network.hasInternet(),
          'dhcp': Network.dhcp(),
          'mode': Network.activeNic(),
          'physicalCon': Network.mediaConnected(),
      })
      callback(null, true)
  }

  window.Network = Network;

  if (typeof module === 'object') {
      module.exports = Network;
  }
})();