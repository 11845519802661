;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var device = {
		keys: {}
    };
	var powerState = "Panel On"
	var lastKeysPressed = []
	
    /********************/
    /* Public Functions */
	/********************/
    var Device = {
        // Initialize function
		init: function(callback) {
			setKeys();
			initDeviceStaticParams(function(result){ })
			this.getAndroidAppVersion()
			this.getJEDIVersion()
			this.getDeviceSerialNumber()
			this.setCurrentPowerStatus()

			setTimeout(setDeviceCapabilities, 100)

			/*setInterval(function(){
				android.log(this.socketio.connected)
            },10000);*/
            
			typeof callback === 'function' && callback(this)
			return this;

		},

		getAndroidAppVersion(){
			console.log('Hoteplus app version: '+android.appVersion())
		},

		getJEDIVersion(){
			console.log("JEDI "+android.getJEDIVersion())
		},

		getDeviceSerialNumber(){
			console.log("S/N "+android.getDeviceSerialNumber())
		},

		checkIn(guest) {
			android.checkIn(guest.title+' '+guest.lastName+' '+guest.firstName)
			return true
		},
		
		checkOut: function() {
			this.clearUserData()
			return android.checkOut()
		},

		tvPMSStatus(checkedIn){
			console.log('Hoteplus guest: '+this.pmsStatus()+', current TV status: '+checkedIn)
			if(checkedIn!=this.pmsStatus()){
				if(this.pmsStatus()){
					console.log('Mismatch PMS status, checking in...')
					this.checkIn(global.vm.$root.guest)
				}
				else {
					console.log('Mismatch PMS status, checking out...')
					this.checkOut()
				}
			}

		},

		pmsStatus() {
			//console.log(global.vm.$root.guest)
			if(Object.keys(global.vm.$root.guest).length == 0){
				return false
			}
			else {
				return true
			}
		},

		persistUserData() {
			return true
		},

		clearUserData: function() {
			return true
		},

        keys: function() {
            return device.keys;
		},
		
		width: function() {
			return android.getDeviceWidth();
        },
        
        height: function() {
			return android.getDeviceHeight(); 
        },
        
        browser: function() {
            return navigator.userAgent;
		},
		
		firmware: function() {
			return android.firmware();
		},

		vendor: function() {
			return android.manufacturer();
		},

		model: function() {
			return android.model();
		},

		serialNumber: function() {
			return android.getDeviceSerialNumber()
		},

		productCode: function() {
			return android.deviceID();
		},

		country: function() {
			var country = null;

			try { country = String(window.navigator.language).toLowerCase().split('-')[1]; }
			catch (e) { }

			return country;
		},

		language: function() {
			var language = null;

			try { language = String(window.navigator.language).toLowerCase() }
			catch (e) { }

			return language;
		},

		date: function() {
			return new Date();
		},

		timeZoneOffset: function() {
			return new Date().getTimezoneOffset();
		},

		clearHistory: function() {
			return null;
		},

		apps: function() {
			console.log('hoteplus is asking for available apps')
			return JSON.parse(android.getAvailableApps())
		},
		
		openApps: function() {
			global.vm.$root.visible.apps = true
		},
		
		launchApp: function(appName) {
			console.log('launching '+appName)
			android.launchApp(appName)
			global.vm.$root.visible.apps = false
		},
		
		closeApps: function() {
			//console.log('closing apps')
			global.vm.$root.visible.project = true
		},
        
    	openBrowser: function(url) {
			android.openBrowser(url);
			return null;
		},

		openWeather: function() {
			if(global.vm.$root.storage.offlineMode()) return global.vm.$root.$notify("Not available when offline")
			global.vm.$root.hideAllComps()
			global.vm.$root.visible.weather = true
		},

		openSleepTimer: function() {
			if(global.vm.$root.storage.offlineMode()) return global.vm.$root.$notify("Not available when offline")
			global.vm.$root.hideAllComps()
			global.vm.$root.visible.sleepTimer = true
		},
		
		openAirTime: function() {
			android.showToast('Airtime app not available');
            return
		},

		pushHistory: function() {
			return null;
		},

		exit: function(dvb) {
			return null;
		},
		
		volume: function(volume) {
			if(volume === undefined){
			   return parseInt(android.getVolume());
			}
			else {
				if(android.setVolume(volume)){
					volume = parseInt(android.getVolume())
					EventBus.$emit('deviceDataChanged', {'volume': volume});
					return volume;
				}
			}
        },

        mute: function(mute) {
			EventBus.$emit('deviceDataChanged', {'volume': 0});
			return android.mute();
		},

		remote: function(key) {
			if(key=="26"){
				android.getPowerStatus()=='on' ? android.remoteCommand('off') : android.remoteCommand('on')
			}
			if(android.getPowerStatus()=='on'){
				// Combination right,right,left,up,down,ok sends error report to cloud server
				if(key=="37" || key=="38" || key=="39" || key=="40" || key=="13"){
					lastKeysPressed.push(key)
				}
				else{
					lastKeysPressed=[]
				}	
				if(lastKeysPressed.length > 5){ 
					if(lastKeysPressed[lastKeysPressed.length-1]=="13" && lastKeysPressed[lastKeysPressed.length-2]=="40" && lastKeysPressed[lastKeysPressed.length-3]=="38"
					&& lastKeysPressed[lastKeysPressed.length-4]=="37" && lastKeysPressed[lastKeysPressed.length-5]=="39" && lastKeysPressed[lastKeysPressed.length-6]=="39"){
						android.sendErrorReport()
					}
				}
				global.vm.$root.triggerKeyboardEvent(document, key)
			}
			else{
				console.log('Not allowed when TV is in standby')
			}
		},

		updateHoteplusApp: function() {
			return true
		},

		updateConfiguration: function(config) {
			return true
		},
		
		power: function(state) {
			//console.log('powerstate: '+state)
			if(state === undefined){return 'Panel On'}
			
			else {
				if(state.toLowerCase() === 'on'){
					android.remoteCommand('on')
				}
				else if(state.toLowerCase() === 'off'){
					android.remoteCommand('off')
				}
				else if(state.toLowerCase() === 'reboot'){
					android.remoteCommand('reboot')
				}
				else if(state.toLowerCase() === 'reload'){
					android.remoteCommand('reload')
				}
			}
        },
		
		tvPower: function(state){
			//console.log('state: '+state)
			if(state.toLowerCase() === 'on'){
				powerState='Panel On'
				EventBus.$emit('powerStateChanged', 'Panel On'); 
				return true
			}
			else if(state.toLowerCase() === 'standby'){
				powerState='Stand By'
				EventBus.$emit('powerStateChanged', 'Stand By'); 
				return true
			}
		},

		setCurrentPowerStatus: function(){
			if(android.getPowerStatus()=="on"){
				return 'Panel On'
			}
			else{
				return 'Stand By'
			}
		},
		
		source: function(source) {
			if(android.getPowerStatus()=='on'){
				if(source === undefined) return "TV"
				//if(source === "TV") global.vm.$root.visible.project = true
				var sourceChanged = android.changeSource(source)
				if(sourceChanged && source!='TV'){
					//global.vm.$root.visible.project = false
					return source
				}
				else{
					global.vm.$root.visible.project = true
					//console.log('Can not change source')
				}
			}
			else{
				console.log('Not allowed when TV is in standby')
			}
		},
		
		getAppVersion: function(){
			return android.appVersion();
		}
    }
        
    /*********************/
    /* Private Functions */
    /*********************/
	
	function setKeys() {
		device.keys = {
			RIGHT: 39,
			LEFT: 37,
			UP: 38,
			DOWN: 40,
			RETURN: 8,
			EXIT: 27,
			ENTER: 13,
			HOME: 72,
			PUP: 33,
			PDOWN: 34,
			PLAY: 415,
			PAUSE: 19,
			STOP: 413,
			FF: 417,
			RW: 412,
			RED: 120,
			GREEN: 121,
			YELLOW: 122,
			BLUE: 123,
			ZERO: 48,
			ONE: 49,
			TWO: 50,
			THREE: 51,
			FOUR: 52,
			FIVE: 53,
			SIX: 54,
			SEVEN: 55,
			EIGHT: 56,
			NINE: 57,
			VUP: 24,
			VDOWN: 25,
			GUIDE: 118,
			CHLIST: 119,
			POWER: 26,
			PRECH: 46, // Delete
			TXTMIX: 1100 // ,Del
		}
	}

	function initDeviceStaticParams(callback) {
        EventBus.$emit('deviceDataChanged', {
            'volume': Device.volume(),
            'vendor': Device.vendor(),
            'browser': Device.browser(),
            'firmware': Device.firmware(),
			'model': Device.model(),
			'serial': Device.serialNumber(),
			'state': Device.setCurrentPowerStatus()
        })
        callback(true)
    }

	function setDeviceCapabilities() {
		global.vm.$root.capabilities.css.filterBlur = true
		global.vm.$root.capabilities.css.svgBlur = true
	}
	
    window.Device = Device;

    if (typeof module === 'object') {
        module.exports = Device
    }
})();