;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var network = {
        physicalCon: true, mode: 1, local: true, dhcp: true, internet: true,
        mac: '001122445566', macw: '001122445566', ip: '0.0.0.0', gw: '0.0.0.0', sm: '0.0.0.0', dns1: '0.0.0.0', dns2: '0.0.0.0'
    };
    var deviceInfo = ""
    /********************/
    /* Public Functions */
	/********************/
    var Network = {
        // Initialize function
		init: function(callback) {
            deviceInfo = new DeviceInfo

            // Store MACs
            deviceInfo.getNetworkMacInfo(
                function(info) { 
                    if('wiredInfo' in info) network.mac = info.wiredInfo.macAddress.replace(/:/g, '').toUpperCase()
                    if('wifiInfo' in info) network.macw = info.wifiInfo.macAddress.replace(/:/g, '').toUpperCase() 
                    EventBus.$emit('deviceDataChanged', network)
                    callback(null, this)
                    initNetworkParams()
                },
                function(e) {console.error('Failed to get MAC INFO')}
            )
            
            // periodically check for network changes
            setInterval(() => {
                initNetworkParams()
            }, 5000);
        },

        openNetflix(reason) {
            console.error('Netflix is not available in this device')
            return global.vm.$root.$notify("Netflix is not available in this device")
        },

        openBluetoothPlayer: function() {
            return console.warn('Bluetooth Player is not available')
        },

        openScreenMirroring: function() {
            return console.warn('Screen Mirroring is not available')
        },

        activeNic: function() {
            return network.mode;
        },
        
        // Get/Set the IP DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        ipDhcpEnabled: function(mode = '-1') {
            // SET
            if(mode == true || mode == false ){
                return false;
            } 

            // GET
            return network.dhcp
        },

        // Get/Set the DNS DHCP state of the NIC card
        // 1 if enabled, 0 if manual, -1 if fail
        dnsDhcpEnabled: function(mode = '-1') {
            // SET
            if(mode == true || mode == false ){
                return false;
            } 

            // GET
            return 'Unknown';
        },

        mediaConnected: function(nic) {
            return network.physicalCon;
        },

        gatewayReachable: function(nic) {
            return network.local;
        },
        
        dnsReachable: function(nic) {
            return network.local;
        },
        
        hasInternet: function(nic) {
            return network.internet;
        },
        
        MAC: function() {
            //return Math.random()
            return network.mac;
        },

        MACw: function() {
            //return Math.random()
            return network.macw;
        },

        IP: function(nic) {
            return network.ip;
        },

        GW: function(nic) {
            return network.gw;
        },

        SM: function(nic) {
            return network.sm;
        },

        DNS: function(nic) {
            return network.dns1;
        },

        // SoftAp Functions
        //
        softApEnabled(enable = '-1'){
            return false
        },
        
        SSID(name = null){
            return 'N/A'
        },

        // Get the Soft AP password
        softApPass() {
            return 'N/A'
        },

        // Enable or disable the auto regeneration of password at reboot
        softApAutoResetPass(enable = -1) {
            return false
        },

        softApStrength(strength = 0) {
            return 'N/A'
        },

        softApChannel(ch = 0) {
            return 'N/A'
        }
    }
        
    /*********************/
    /* Private Functions */
    /*********************/

    function initNetworkParams() {
        // {
        //  "wifi":{"tetheringEnabled":false,"state":"disconnected"},
        //  "isInternetConnectionAvailable":false,"subscribed":false,"offlineMode":"disabled",
        //  "wired":{"netmask":"255.255.255.0","dns1":"192.168.10.1","dns2":"1.1.1.1","ipAddress":"192.168.10.238","onInternet":"no","method":"dhcp","state":"connected","gateway":"192.168.10.1","interfaceName":"eth0","plugged":true},
        //  "wifiDirect":{"state":"disconnected"}
        // }
        // physicalCon: true, mode: 1, local: true, internet: true,
        // mac: '001122445566', macw: '001122445566', dhcp: true, ip: '0.0.0.0', gw: '0.0.0.0', sm: '0.0.0.0', dns1: '0.0.0.0', dns2: '0.0.0.0'
        deviceInfo.getNetworkInfo(
            function(netInfo) { 
                var newNet = JSON.parse(JSON.stringify(network))
                
                // find the currently alive NIC
                if(netInfo.wired.state === 'connected') newNet.mode = 1                 // wired
                else if(netInfo.wifi.state === 'connected') newNet.mode = 0             // wireless
                else newNet.mode = -1                                                   // disconnected
                var nicKey = (newNet.mode === 1 ? 'wired' : 'wifi')
                
                if(newNet.mode !== -1) {
                    newNet.physicalCon = netInfo[nicKey].plugged
                    newNet.local = netInfo[nicKey].state === 'connected'
                    newNet.internet = netInfo.isInternetConnectionAvailable
                    newNet.dhcp = netInfo[nicKey].method === 'dhcp'
                    newNet.ip = netInfo[nicKey].ipAddress
                    newNet.gw = netInfo[nicKey].gateway
                    newNet.sm = netInfo[nicKey].netmask
                    newNet.dns1 = netInfo[nicKey].dns1
                    newNet.dns2 = netInfo[nicKey].dns2
                }
                else {
                    newNet.physicalCon = false
                    newNet.local = false
                    newNet.internet = false
                    newNet.ip = '0.0.0.0'
                    newNet.gw = '0.0.0.0'
                    newNet.sm = '0.0.0.0'
                    newNet.dns1 = '0.0.0.0'
                    newNet.dns2 = '0.0.0.0'
                }

                // If there are changes inform main and Core
                if(JSON.stringify(newNet) !== JSON.stringify(network)) {
                    network = JSON.parse(JSON.stringify(newNet))
                    EventBus.$emit('deviceDataChanged', newNet)
                }
            },
            function(e) {
                console.error('Could not get Network Info')
            }
        )
    }
    
    window.Network = Network;

    if (typeof module === 'object') {
        module.exports = Network;
    }
})();