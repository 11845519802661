;(function($) {
    'use strict';

  var device = {
    keys: {}
  };

  var panel = 'unknown'
	
  var Device = {
    init: function(callback) {
      initDeviceStaticParams()
      setKeys()
      typeof callback === 'function' && callback(this)
      return this;
    },

    log: function(s){
      console.log(s)
    },

    getAppVersion: function(){
      console.log('Hstb app version: '+android.appVersion())
    },

    keys: function() {
      return device.keys
		},
		
		width: function() {
      return android.getDeviceWidth()
    },
    
    height: function() {
      return android.getDeviceHeight();
    },
    
    browser: function() {
        return navigator.userAgent
		},
		
		firmware: function() {
			return android.firmware()
		},

		vendor: function() {
      try{
        return android.manufacturer()
      }
			catch(e) { 
        return 'unknown'
      }
		},

		model: function() {
			return android.model()
		},

		serial: function() {
			return android.getMAC().replace(/:/g, '').toUpperCase()
		},
		
		volume: function(volume) {
			if(volume === undefined){
			   return android.getVolume()
			}
			else {
				if(android.setVolume(volume)){
					volume = android.getVolume()
					EventBus.$emit('deviceDataChanged', {'volume': volume})
					return volume;
				}
			}
    },

    mute: function(mute) {
			EventBus.$emit('deviceDataChanged', {'volume': 0})
			return android.mute()
		},

		remote: function(key) {			
			global.vm.$root.triggerKeyboardEvent(document, key)
      if(key == '26'){
        if(!panel){
          this.setPower(true)
        }
        else{
          this.setPower(false)
        }
      }
		},

		updateHoteplus: function() {
			return false
		},

		updateConfiguration: function() {
			return true
		},
		
    reboot: function(){
      android.remoteCommand('reload')
    },

    panel: function(val) {
      if(val){
        setPower(val)
      }
      return panel
    },

		source: function(source) {
      if(source === undefined) return "TV"
      if(android.remoteCommand(source)){
        global.vm.$root.visible.project = true
        return source
      }
      else{
        console.log('hdmi cec is turned off')
        global.vm.$root.visible.project = true
      }
		}
  }

  function setPower(state){
    if(state){
      android.remoteCommand('on')
    }
    else if(!state){
      android.remoteCommand('off')
    }
    else {
      android.remoteCommand('reload')
    }
  }

	function setKeys() {
		device.keys = {
			RIGHT: 39,
			LEFT: 37,
			UP: 38,
			DOWN: 40,
			RETURN: 8,
			EXIT: 27,
			ENTER: 13,
			HOME: 36,
			VUP: 24,
			VDOWN: 25,
			POWER: 26,
		}
	}

	function initDeviceStaticParams() {
    EventBus.$emit('deviceDataChanged', {
        'volume': Device.volume(),
        'vendor': Device.vendor(),
        'browser': Device.browser(),
        'firmware': Device.firmware(),
        'model': Device.model(),
        'serial': Device.serial()
    })
  }

  window.Device = Device;

  if (typeof module === 'object') {
      module.exports = Device;
  }

})();