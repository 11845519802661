<template>
  <div :style="makePageFullScreen()" >
    <div :style="placeAtTheCenter()">
      <h1>This device is not compatible with our software</h1>
    </div>
  </div>
</template>

<script>
import socket from 'socket.io-client'

export default {
  name: 'IncompatiblePage',
  props:{
    loading:Boolean
  },
  components: {
    
  },
  data() {
    return {
      userAgent:navigator.userAgent,
      width:window.innerWidth,
      height:window.innerHeight,
      network:{},
      device:{}
    }
  },
  mounted(){
    console.log('mounted incompatible')
  },
  methods:{
     placeAtTheCenter(){
      let textCss = 'position:absolute;width:100%;height:200px;'
      textCss+='top:'+((this.height - 200) /2)+'px;'
      return textCss
    },
    
    makePageFullScreen(){
      var css='background-color:#262020;'
      css+='width:'+this.width+'px;'
      css+='height:'+this.height+'px;'
      css+='color:white;'
      return css
    }
  }, 
}
</script>
<style scoped>
.bg{
  background-color: #262020;
}
</style>

