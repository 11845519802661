;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var storage = {
    };
    var cachedLauncherAddress = undefined

    var storageDir = {}
    var production = process.env.NODE_ENV === 'production'

    /********************/
    /* Public Functions */
	/********************/
    var Storage = {
        // Initialize function
		init: function(callback) {
            var self = this
            // Check if downloading is supported in this device (just check, continue after all)
            if(!tizen.systeminfo.getCapability("http://tizen.org/feature/download")) console.error('Download API is not supported in this device')
            
            tizen.filesystem.resolve('documents',
                function(dir) { 
                    storageDir = dir

                    self.createFile('hoteplus.txt', function(error, result) {
                        if(error) return console.error('Failed to create data file ' + error)
                        self.readData(function(error, data) {
                            if(error) return console.error('Failed to read data from filesystem ' + error)
                            self.data = data

                            self.createDir('dist', function(error, result) {
                                if(error) console.error('Failed to create dist folder ' + error)
                            })
                            self.createDir('contents', function(error, result) {
                                if(error) console.error('Failed to create contents folder ' + error)
                            })

                            //downloadFromQueue()
                            if (callback) return callback(self);
                        });
                    })
                }, 
                function(e) { console.error("Error reading storage, " + e.message) }, 
                "rw"
            );
        },

        // Set value to the storage
        // PARAMS: {String} name, {Object/String/Number} value
        // RETURNS: {Boolean}
        set: function(name, value) {
            this.data[name] = value;
	        this.writeData(this.data, function(error, result) {
                if(error) {
                    console.error('Failed to write data, ' + error)
                    return false
                }
                return true
            });
        },
        
        // Get value from the storage
        // PARAMS: {String} name
        // RETURNS: {Object/String/Number} or FALSE 
        get: function(name) {
            return this.data[name];
        },

        // Clear all stored data
        // RETURNS: {Boolean}
        clear: function() {
            this.data = {};
	        this.writeData(this.data, function(error, result){
                if(error) {
                    console.error('Failed to clear data, ' + error)
                    return false
                }
                return true
            });
        },

        // Get data from the Tizen File System
        // PRIVATE
        readData: function(callback) {
            this.fileExists('hoteplus.txt', function(error, handler) {
                if(error) return callback('failed to read data, ' + error, false)
                if(!handler) return callback('failed to read data, data file does not exist', false)
                handler.readAsText(
                    function(text) { 
                        if(text.length === 0) return callback(null, {})
                        callback(null, JSON.parse(text)) 
                    }, 
                    function(e) { 
                        console.log(e)
                        callback(e.message, false) }, 
                    "UTF-8"
                );
            })
        },

        // Store data to the Tizen File System
        // PRIVATE
        writeData: function(data, callback){
            this.fileExists('hoteplus.txt', function(error, handler) {
                if(error) return callback('failed to write data, ' + error)
                if(!handler) return callback('failed to read data, data file does not exist', false)
                handler.openStream(
                    "w",
                    function(fs) {
                        fs.write(JSON.stringify(data));
                        fs.close();
                        callback(null, true)
                    }, 
                    function(e) { callback(e.message, false) }, 
                    "UTF-8"
                );
            })
        },

        // Create folder
        // RETURNS: {Boolean}
        createDir: function(dirName, callback) {
            this.dirExists(dirName, function(error, dirHandler) {
                if(error) return callback(error, false)
                if(dirHandler) return callback(null, dirHandler)      // dir allready exists
                
                try { return callback(null, storageDir.createDirectory(dirName)) }
                catch(e) { callback(e.message, false) }
            })
        },

        // Delete folder
        // RETURNS: {Boolean}
        deleteDir: function(dirName, calllback) {
            this.dirExists(dirName, function(error, dirHandler) {
                if(error) return callback(error, false)
                if(!dirHandler) return callback(null, true)      // dir does not exist
                if(! 'fullPath' in dirHandler) return callback('Wrong dir handler provided', false)
                
                storageDir.deleteDirectory(
                    dirName.fullPath,
                    true,       // recursive
                    function() { callback(null, true) },
                    function(e) { callback(e.message, false) }
                )
            })
        },

        // Create file
        // RETURNS: {Boolean}
        createFile: function(fileName, callback) {
            this.fileExists(fileName, function(error, fileHandler) {
                if(error) return callback(error, false)
                if(fileHandler) return callback(null, fileHandler)      // file allready exists
                
                try { return callback(null, storageDir.createFile(fileName)) }
                catch(e) { callback(e.message, false) }
            })
        },

        // Delete file
        // RETURNS: {Boolean}
        deleteFile: function(fileName, callback) {
            this.fileExists(fileName, function(error, fileHandler) {
                if(error) return callback(error, false)
                if(!fileHandler) return callback(null, true)      // file does not exist
                if(! 'fullPath' in fileHandler) return callback('Wrong dir handler provided', false)
                
                storageDir.deleteFile(
                    fileName.fullPath,
                    function() { callback(null, true) },
                    function(e) { callback(e.message, false) }
                )
            })
        },

        // Check if folder exists
        // RETURNS: {Boolean}
        dirExists: function(name, callback) {
            storageDir.listFiles(
                function(elements) {
                    for(var i = 0; i < elements.length; i++) {
                        if(elements[i].name !== name || !elements[i].isDirectory) continue
                        return callback(null, elements[i])      // return this dir's handler
                    }
                    return callback(null, false)
                },
                function(e) { callback(e.message, false) }
            )
        },

        // Check if file exists
        // RETURNS: {Boolean}
        fileExists: function(name, callback) {
            storageDir.listFiles(
                function(elements) {
                    for(var i = 0; i < elements.length; i++) {
                        if(elements[i].name !== name || elements[i].isDirectory) continue
                        return callback(null, elements[i])      // return this file's handler
                    }
                    return callback(null, false)
                },
                function(e) { callback(e.message, false) }
            )
        },

        offlineMode() { return false },
        launcherURL() { 
            if(cachedLauncherAddress) return cachedLauncherAddress
            cachedLauncherAddress = b2bapis.b2bcontrol.getURLLauncherAddress()
            return cachedLauncherAddress
        },
        serverHost() { 
            var host = this.launcherURL() || 'http://192.168.10.100/tv'
            host = host.split('//')[1].split('/')[0]
            host = host.split(':')[0]   // remove port if any

            return host
        },
        serverProtocol() { return  (this.launcherURL() || 'http://192.168.10.100/tv').split('//')[0] },
        serverPort() { 
            var port = (this.launcherURL() || 'http://192.168.10.100/tv').split('//')[1]
            if(port.indexOf(':') !== -1) port = port.split(':')[1]
            else this.serverProtocol === 'https:' ? port = '443/tv' : port = '80/tv'

            if(port.indexOf('/') !== -1) port = Number(port.split('/')[0])
            else this.serverProtocol === 'https:' ? port = 443 : port = 80

            return Number(port)
        },
        serverURL() { return !production ? this.serverProtocol() + '//' + this.serverHost() : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() },
        mobilePath() { return !production ? this.serverProtocol() + '//' + this.serverHost() + ':8082' : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() + '/mobile' },
        assetsPath() { return this.serverURL() + '/contents/' },
        proxyPath() { return this.serverProtocol() + '//' + this.serverHost() + ':1' + this.serverPort() + '/fetch/' },

        // Handles downloading application to local storage for offline operation
        // RETURNS: {Boolean}
        storeAppOffline: function(callback) {
            callback(null, true)
        },

        // RETURNS: {Boolean}
        removeAppOffline: function() {
        },

        // Handles downloading project static assets to local storage for offline operation
        // RETURNS: {Boolean}
        storeProjectDataOffline: function(projectObj, callback) {
            callback(null, true)
        },
    }
        
    // Private Functions 
    
    window.ScreenStorage = Storage;

    if (typeof module === 'object') {
        module.exports = ScreenStorage;
    }
})();