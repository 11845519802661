<template>
  <div id="app">
    <div>
      <Splash 
        v-if="splashPage" 
        :loading="splashPage" 
        @stopSplash="stopSplash" 
        @showIncompatiblePage="showIncompatiblePage"
        @showMain="showMain"
        @showRegisterPage="showRegisterPage"
        />

      <IncompatiblePage v-if="incompatiblePage"/>
      <Main v-if="mainPage" @deleted="showRegisterPage"/>
      <Register v-if="registerPage" :number="association_number" @registerComplete="showMain"/>
    </div>
    
      
  </div>
</template>

<script>
import Splash from './pages/Splash.vue'
import Main from './pages/Main.vue'
import Incompatible from './pages/Incompatible.vue'
import Register from './pages/Register.vue'

export default {
  name: 'App',
  components: {
    Splash,
    Main,
    Incompatible,
    Register
  },
  data(){
    return{
      splashPage:true,
      incompatiblePage:false,
      mainPage:false,
      registerPage:false,
      association_number:0
    }
  },
  mounted(){
    console.log('mounted app')
    EventBus.$on('showPage', (data) => {
     console.log(data)
     if(data.page=='Register'){
      this.showRegisterPage(data.association_number)
     }
     if(data.page=='Main'){
      this.showMainPage(data.association_number)
     }
    })
  },
  beforeUnmount(){
    console.log('unmounted')
  },
  methods:{
    showIncompatiblePage(){
      console.log('incompatible page')
      this.incompatiblePage=true
      this.splashPage=false
      this.mainPage=false
      this.registerPage=false
    },
    showMainPage(num){
      console.log('landing page')
      this.uid=num
      this.mainPage=true
      this.incompatiblePage=false
      this.splashPage=false
      this.registerPage=false
    },
    showRegisterPage(num){
      //console.log(num)
      this.association_number=num
      this.registerPage=true
      this.incompatiblePage=false
      this.splashPage=false
      this.mainPage=false
    },
    stopSplash(){
      this.splash=false
    }
  }
}
</script>

<style>
#app {
  font-family: "Comforta", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
