;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var network = {        
    };
    /********************/
    /* Public Functions */
	/********************/
    var Network = {
        // Initialize function
		init: function(callback) {
      var self = this
      initNetworkParams(function(error, net) {
        if(error) console.error('Could not initialize network ' + error)
        callback(null, self)
      })
    },

    openNetflix(reason) {
      console.error('Netflix is not available in this device')
      return global.vm.$root.$notify("Netflix is not available in this device")
    },

    openBluetoothPlayer: function() {
      return console.warn('Bluetooth Player is not available')
    },

    openScreenMirroring: function() {
      return console.warn('Screen Mirroring is not available')
    },

    activeNic: function() {
      if(Raspberry.getType()=='Wired'){
        return 1
      }
      return 2
    },
        
    // Get/Set the IP DHCP state of the NIC card
    // 1 if enabled, 0 if manual, -1 if fail
    ipDhcpEnabled: function(mode = '-1') {
        // SET
        if(mode == true || mode == false ){
            return false;
        } 

        // GET
        return 'Unknown';
    },

    // Get/Set the DNS DHCP state of the NIC card
    // 1 if enabled, 0 if manual, -1 if fail
    dnsDhcpEnabled: function(mode = '-1') {
        // SET
        if(mode == true || mode == false ){
            return false;
        } 

        // GET
        return 'Unknown';
    },

    mediaConnected: function(nic) {
        return 'Unknown';
    },

    gatewayReachable: function(nic) {
      try{
        return Raspberry.getLocal()
      }catch(e){
        return true
      }
    },
    
    dnsReachable: function(nic) {
        return 'Unknown';
    },
    
    hasInternet: function(nic) {
      try{
        return Raspberry.getInternet()
      }catch(e){
        return true
      }
    },
    
    MAC:function() {
      try{
        return Raspberry.getMac()
      }catch(e){
        return '111122445566'
      }
    },
    MACw: function() {
      try{
        Raspberry.getWiFiMac()
      }catch(e){
        return '001122445566'
      }
    },

    IP: function(nic) {
      try{
        return Raspberry.getIP(nic)
      }catch(e){
        return '0.0.0.0'
      }
    },

    GW: function(nic) {
      try{
        Raspberry.getGateway(nic)
      }catch(e){
        console.log(e)
        return '0.0.0.0'
      }
    },

    SM: function(nic) {
      try{
        Raspberry.getSubnet()
      }
      catch(e){
        return '24'
      }
    },

    DNS: function(nic) {
        return '0.0.0.0';
    },

    // SoftAp Functions
    //
    softApEnabled(enable = '-1'){
        return false
    },
    
    SSID(name = null){
        return 'N/A'
    },

    // Get the Soft AP password
    softApPass() {
        return 'N/A'
    },

    // Enable or disable the auto regeneration of password at reboot
    softApAutoResetPass(enable = -1) {
        return false
    },

    softApStrength(strength = 0) {
        return 'N/A'
    },

    softApChannel(ch = 0) {
        return 'N/A'
    }
}
        
    /*********************/
    /* Private Functions */
    /*********************/

    function initNetworkParams(callback) {
        EventBus.$emit('deviceDataChanged', { 
            'mac': Network.MAC(),
            'macw': Network.MACw(),
            'ip': Network.IP(),
            'mode': Network.activeNic(),
            'sm': Network.SM(),
            'gw': Network.GW(),
            'local': Network.gatewayReachable(),
            'internet': Network.hasInternet(),
            'dhcp': Network.ipDhcpEnabled(),
            'dns1': Network.DNS()
        })
        callback(null, true)
    }
    
    window.Network = Network;

    if (typeof module === 'object') {
        module.exports = Network;
    }
})();