(function($) {
    'use strict'

    // Global Configuration 
  var network = {}

  var Network = {
		init: function(callback) {
      console.log('initializing network data')
      initNetworkParams((function(error, net) {
        if(error) console.error('Could not initialize network ' + error)
        else{
          callback(null, net)
        }
      }).bind(this))
    },

    openScreenMirroring: function() {
      return console.warn('Screen Mirroring is not available')
    },

    activeNic: function() {
      return 'LAN'
    },
        
    ipDhcpEnabled: function(mode = '-1') {
      // SET
      if(mode == true || mode == false ){
          return false
      } 
      // GET
      return 'Unknown'
    },

    // Get/Set the DNS DHCP state of the NIC card
    dhcp: function(mode = '-1') {
      // SET
      if(mode == true || mode == false ){
          return false
      } 

      // GET
      return 'Unknown'
    },

    mediaConnected: function(nic) {
        return 'Unknown'
    },

    gatewayReachable: function(nic) {
        return 'Unknown'
    },
    
    dnsReachable: function(nic) {
        return 'Unknown'
    },
    
    hasInternet: function(nic) {
        return 'Unknown'
    },
    
    mac: function() {
        //return Math.random()
        return '001122445566'
    },

    macWireless: function() {
      return '001122445566'
    },

    ip: function(nic) {
      return '0.0.0.0'
    },

    gateway: function(nic) {
      return '0.0.0.0'
    },

    subnet: function(nic) {
      return '0.0.0.0'
    },

    dns: function(nic) {
      return '0.0.0.0'
    },

    // SoftAp Functions
    softApEnabled(enable = '-1'){
      return false
    },
        
    ssid(name = null){
      return 'N/A'
    },

    // Get the Soft AP password
    pass() {
      return 'N/A'
    },

    // Enable or disable the auto regeneration of password at reboot
    autoResetPass(enable = -1) {
      return false
    },
  }
        
  // Private Functions 
  function initNetworkParams(callback) {
    var networkData={
      'mac': Network.mac(),
      'macw': Network.macWireless(),
      'ip': Network.ip(),
      'sm': Network.subnet(),
      'gw': Network.gateway(),
      'dhcp': Network.dhcp(),
      'dns1': Network.dns()
    }
    /*EventBus.$emit('deviceDataChanged', { 
      'mac': Network.MAC(),
      'macw': Network.MACw(),
      'ip': Network.IP(),
      'sm': Network.SM(),
      'gw': Network.GW(),
      'dhcp': Network.ipDhcpEnabled(),
      'dns1': Network.DNS()
    })*/
    callback(false, networkData)
  }
  
  window.Network = Network;

  if (typeof module === 'object') {
      module.exports = Network
  }
})()