import Vue from 'vue'
import App from './App.vue'
import SocketPlugin from './plugins/socketPlugin'
import ParseDevice from './plugins/devicePlugin'
import VueQRCodeComponent from 'vue-qrcode-component'
import { EventBus } from './plugins/eventPlugin'

Vue.config.productionTip = false
import './css/style.css'

global.EventBus = EventBus

new Vue({
  el: '#app',
  render: h => h(App),
  data() {
    return {
     
    }
  },
  mounted(){
    // maybe convert parse and make it return callback so emit comes after parse?
    ParseDevice.parse()

    const network=window.Network
    const device=window.Device
    const screenStorage=window.ScreenStorage

    let host = 'backend-worker.saloustrosm.workers.dev';
    const uid = window.Device.serial();

    const token = '$ign@g3dev1Ce';
    let connectionUrl = `wss://${host}/screen?uid=${uid}&token=${token}`
    if(process.env.NODE_ENV === 'development'){
      host = 'localhost'
      connectionUrl = `ws://${host}:8787/screen?uid=${uid}&token=${token}`
    }
    Vue.use(SocketPlugin, { connectionUrl: connectionUrl})

    Vue.component('qr-code', VueQRCodeComponent)
    EventBus.$emit('appMounted')

    this.initSocketListeners()
    EventBus.$on('connected',function(){
      if (this.$socket.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({
          event: 'connectionEstablished',
          data: {
            uid: uid,
            panel:device.panel(),
            volume:device.volume(),
            vendor:device.vendor(),
            model:device.model(),
            firmware:device.firmware(),
            userAgent:device.browser(),
            width:device.width(),
            height:device.height(),
            mac:network.mac(),
            ip:network.ip(),
            gateway:network.gateway(),
            dns:network.dns(),
            used:screenStorage.used(),
            total:screenStorage.total()
          },
        });
        this.$socket.send(message);
      } 
    })
  },
  methods:{
    initSocketListeners(){
      console.log('init listeners')
      this.$socket.onmessage = (event) => {
        //console.log('Message received:', event.data);
        //EventBus.$emit('messageReceived', event.data);
        const res = JSON.parse(event.data)
        if(res.event==='welcome' || res.event==='inserted'){
          const dbValue = res.data.value
          console.log(dbValue)
          if(dbValue.associated_site==null){
            EventBus.$emit('showPage', ({page:'Register', association_number:dbValue.association_number}))
          }
          else{
            EventBus.$emit('showPage', ({page:'Main', association_number:dbValue.association_number}))
          }
        }
        else if(res.event==='report'){
          this.sendReport()
        }
        else if(res.event==='otherEvent'){

        }
      };
    },
    sendReport(){
      const message = JSON.stringify({
        event: 'submitReport',
        data: {
          uid:this.device.serial(),
          panel:this.device.panel(),
          volume:this.device.volume(),
          vendor:this.device.vendor(),
          model:this.device.model(),
          firmware:this.device.firmware(),
          userAgent:this.device.browser(),
          width:this.device.width(),
          height:this.device.height(),
          mac:this.network.mac(),
          ip:this.network.ip(),
          gateway:this.network.gateway(),
          dns:this.network.dns(),
          used:this.screenStorage.used(),
          total:this.screenStorage.total()
        },
      });
      console.log('send the submitReport')
      this.$socket.send(message);
    }
  }
})
